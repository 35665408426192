<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_stockUpdateInstruction" />
      <v-form ref="form">
        <v-container fluid>
          <!--検索項目-->
          <v-row>
            <!-- 空白 -->
            <span class="item-spacer" style="float: left">&nbsp;</span>
            <div
              class="display-search-result-row"
              style="float: left; margin-top: 0px; padding-bottom: 20px"
            >
              <span class="toatlNum">{{ searchConditionTitle }}</span>
            </div>
          </v-row>
          <v-row class="search-row">
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 取引先-->
            <div class="date-style" style="float: left; width: 450px">
              <v-text-field
                dense
                v-model="supplierNm"
                :label="$t('label.lbl_supplier')"
                class="txt-single"
                persistent-hint
                :readonly="true"
                pa-3
              />
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 指示内容-->
            <div class="search-autocomplete first-search-item" style="width: 450px">
              <v-text-field
                dense
                v-model="instructionContent"
                :label="$t('label.lbl_instructionContent')"
                class="txt-single"
                :readonly="true"
              />
            </div>
          </v-row>
          <v-row>
            <div
              class="display-search-result-row"
              style="float: left; margin-top: 0px; padding-bottom: 20px; padding-top: 5px"
            >
              <span class="toatlNum">{{ insertScreenTitle }}</span>
            </div>
          </v-row>
          <v-row class="search-row">
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!--変更後ステータス-->
            <div
              class="search-autocomplete"
              style="float: left; width: 300px"
              v-if="
                (instructionFlg == '01' || instructionFlg == '' || instructionFlg == 'null') &&
                changeOkFlg == true
              "
            >
              <v-autocomplete
                dense
                v-model="changeStatusSelected"
                :items="statusList"
                :hint="changeStatus()"
                maxlength="10"
                :label="$t('label.lbl_afterChangeStatus')"
                persistent-hint
                :error-messages="alertMessage"
                :rules="[rules.inputRequired]"
              >
                >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <div
              v-else-if="
                (instructionFlg == '01' || instructionFlg == '' || instructionFlg == 'null') &&
                changeOkFlg == false
              "
            >
              <v-autocomplete
                dense
                v-model="changeStatusSelected"
                :items="statusList"
                :hint="changeStatus()"
                maxlength="10"
                :label="$t('label.lbl_afterChangeStatus')"
                persistent-hint
                :readonly="true"
              >
                >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require" v-if="instructionFlg == '01'" style="float: left">*</span>

            <!--ロット-->
            <!--変更後ロット-->
            <!-- <span class="item-spacer">&nbsp;</span> -->
            <div
              class="serch-textbox-arrivalNo"
              style="float: left; width: 300px"
              v-if="instructionFlg == '02' && changeOkFlg == true"
            >
              <div class="serch-textbox-arrivalNo" style="width: 300px">
                <v-text-field
                  class="txt-single"
                  outlined
                  dense
                  v-model="changedLot"
                  maxlength="20"
                  clear-icon="mdi-close-circle"
                  clearable
                  :label="$t('label.lbl_afterChangeLot')"
                  :error-messages="alertChangedLot"
                />
              </div>
            </div>
            <div
              class="serch-textbox-arrivalNo"
              style="float: left; width: 300px"
              v-else-if="instructionFlg == '02' && changeOkFlg == false"
            >
              <div class="serch-textbox-arrivalNo" style="width: 300px">
                <v-text-field
                  class="txt-single"
                  outlined
                  dense
                  v-model="changedLot"
                  maxlength="20"
                  clear-icon="mdi-close-circle"
                  clearable
                  :label="$t('label.lbl_afterChangeLot')"
                  :error-messages="alertChangedLot"
                  :readonly="true"
                />
              </div>
            </div>
            <!-- <span class="require" v-if="instructionFlg == '02'" style="float: left">*</span> -->

            <!--変更後名義-->
            <div
              class="date-style"
              style="width: 300px"
              id="table-changeSupplier"
              v-if="instructionFlg == '03' && changeOkFlg == true"
            >
              <v-autocomplete
                v-model="changeSupplier"
                :items="changeSupplierList"
                :label="$t('label.lbl_afterChangeSupplier')"
                persistent-hint
                dense
                :hint="setSrhSupplier()"
                :error-messages="alertChangedSupplierMessage"
                :rules="[rules.inputRequired]"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
              <!-- <div class="align-left" v-else>{{ getChangeSupplierText(item) }}</div> -->
            </div>
            <div
              class="date-style"
              style="width: 300px"
              id="table-changeSupplier"
              v-else-if="instructionFlg == '03' && changeOkFlg == false"
            >
              <v-autocomplete
                v-model="changeSupplier"
                :items="changeSupplierList"
                :label="$t('label.lbl_afterChangeSupplier')"
                persistent-hint
                dense
                :hint="setSrhSupplier()"
                :readonly="true"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
              <!-- <div class="align-left" v-else>{{ getChangeSupplierText(item) }}</div> -->
            </div>
            <span class="require" v-if="instructionFlg == '03'">*</span>
            <!--変更後品番/品名-->
            <div
              class="date-style"
              style="width: 300px"
              id="table-changeItemCdName"
              v-if="instructionFlg == '04' && changeOkFlg == true"
            >
              <v-autocomplete
                v-model="changedItemCdSelected"
                :items="changedItemCdList"
                :label="$t('label.lbl_afterChangeItemCdName')"
                persistent-hint
                dense
                @change="(event) => afterChangedProductNm(event)"
                :error-messages="alertChangedItemCdMessage"
                :search-input.sync="afterItemCdSearch"
                :hint="setItemCdChange()"
                class="txt-single"
                :rules="[rules.inputRequired]"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
              <!-- <div class="align-left" v-else>{{ getChangeItemCdNameText(item) }}</div> -->
            </div>
            <div
              class="date-style"
              style="width: 300px"
              id="table-changeItemCdName"
              v-else-if="instructionFlg == '04' && changeOkFlg == false"
            >
              <v-autocomplete
                v-model="changedItemCdSelected"
                :items="changedItemCdList"
                :label="$t('label.lbl_afterChangeItemCdName')"
                persistent-hint
                dense
                @change="(event) => afterChangedProductNm(event)"
                :error-messages="alertChangedItemCdMessage"
                :search-input.sync="afterItemCdSearch"
                :hint="setItemCdChange()"
                class="txt-single"
                :readonly="true"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
              <!-- <div class="align-left" v-else>{{ getChangeItemCdNameText(item) }}</div> -->
            </div>
            <span class="require" v-if="instructionFlg == '04'">*</span>
            <!-- <span class="item-spacer">&nbsp;</span> -->
          </v-row>
          <v-row class="search-row" style="padding-top: 20px">
            <div style="float: left; width: 1380px">
              <!-- 指示No -->
              <div class="search-autocomplete first-search-item" style="float: left; width: 300px">
                <v-text-field
                  outlined
                  dense
                  v-model="txt_instructionNo"
                  maxlength="50"
                  :label="$t('label.lbl_instructionNo')"
                  class="txt-single"
                  :readonly="true"
                />
              </div>
              <span style="float: left" class="require">*</span>
              <!-- 空白 -->
              <span class="item-spacer">&nbsp;</span>

              <!-- 変更理由-->
              <div
                class="search-autocomplete first-search-item"
                style="float: left; width: 250px"
                v-if="changeOkFlg == true"
              >
                <v-autocomplete
                  dense
                  v-model="reasonChangeSelected"
                  :items="reasonChangeList"
                  :label="$t('label.lbl_reasonChange')"
                  :hint="setReasonChange()"
                  class="txt-single"
                  persistent-hint
                >
                  <!-- アイテム一覧の表示 -->
                  <template slot="item" slot-scope="data">
                    <span class="test">
                      {{ data.item.text }}
                    </span>
                  </template>
                  <!-- * -->
                </v-autocomplete>
              </div>
              <div
                v-else
                class="search-autocomplete first-search-item"
                style="float: left; width: 250px"
              >
                <v-autocomplete
                  dense
                  v-model="reasonChangeSelected"
                  :items="reasonChangeList"
                  :label="$t('label.lbl_reasonChange')"
                  :hint="setReasonChange()"
                  class="txt-single"
                  persistent-hint
                  :readonly="true"
                >
                  <!-- アイテム一覧の表示 -->
                  <template slot="item" slot-scope="data">
                    <span class="test">
                      {{ data.item.text }}
                    </span>
                  </template>
                  <!-- * -->
                </v-autocomplete>
              </div>
              <!-- 空白 -->
              <span class="item-spacer">&nbsp;</span>
              <span class="item-spacer">&nbsp;</span>

              <div
                class="search-autocomplete first-search-item"
                style="float: left; width: 430px"
                v-if="changeOkFlg == true"
              >
                <!-- 有償/無償-->
                <div style="float: left">
                  <v-btn-toggle
                    mandatory
                    v-model="isPaid"
                    color="blue darken-2"
                    id="btn-toggle-btn-padding"
                  >
                    <v-btn
                      class="paid-btn"
                      v-for="item in freeCostDivList"
                      :key="item.value"
                      :value="item.value"
                      small
                    >
                      {{ item.text }}</v-btn
                    >
                  </v-btn-toggle>
                </div>

                <!-- 空白 -->
                <span class="item-spacer" style="float: left">&nbsp;</span>

                <!-- 無償理由選択プルダウン-->
                <div class="search-autocomplete" style="float: left">
                  <v-autocomplete
                    dense
                    class="list-single"
                    v-model="freeReasonSelected"
                    :items="freeReasonList"
                    v-bind:disabled="isDisabledFreeReason"
                    :label="$t('label.lbl_freeReason')"
                  >
                    <!-- アイテム一覧の表示 -->
                    <template slot="item" slot-scope="data">
                      <span class="test">
                        {{ data.item.text }}
                      </span>
                    </template>
                    <!-- * -->
                  </v-autocomplete>
                </div>
              </div>
              <div
                v-else
                class="search-autocomplete first-search-item"
                style="float: left; width: 430px"
              >
                <!-- 有償/無償-->
                <div style="float: left">
                  <v-btn-toggle
                    mandatory
                    v-model="isPaid"
                    color="blue darken-2"
                    id="btn-toggle-btn-padding"
                    :readonly="true"
                  >
                    <v-btn
                      class="paid-btn"
                      v-for="item in freeCostDivList"
                      :key="item.value"
                      :value="item.value"
                      small
                      :disabled="changeOkFlg == false"
                    >
                      {{ item.text }}</v-btn
                    >
                  </v-btn-toggle>
                </div>

                <!-- 空白 -->
                <span class="item-spacer" style="float: left">&nbsp;</span>

                <!-- 無償理由選択プルダウン-->
                <div class="search-autocomplete" style="float: left">
                  <v-autocomplete
                    dense
                    class="list-single"
                    v-model="freeReasonSelected"
                    :items="freeReasonList"
                    v-bind:disabled="isDisabledFreeReason"
                    :label="$t('label.lbl_freeReason')"
                    :readonly="true"
                  >
                    <!-- アイテム一覧の表示 -->
                    <template slot="item" slot-scope="data">
                      <span class="test">
                        {{ data.item.text }}
                      </span>
                    </template>
                    <!-- * -->
                  </v-autocomplete>
                </div>
              </div>
              <!-- 空白 -->
              <span class="item-spacer">&nbsp;</span>

              <!-- 作業者 -->
              <div
                class="search-autocomplete"
                style="float: left; padding-bottom: 20px"
                v-if="changeOkFlg == true"
              >
                <v-autocomplete
                  dense
                  v-model="workerSelected"
                  :items="workerList"
                  :label="$t('label.lbl_worker')"
                  :hint="setWorker()"
                  class="txt-single"
                  persistent-hint
                >
                  <!-- アイテム一覧の表示 -->
                  <template slot="item" slot-scope="data">
                    <span class="test">
                      {{ data.item.text }}
                    </span>
                  </template>
                  <!-- * -->
                </v-autocomplete>
              </div>
              <div v-else class="search-autocomplete" style="float: left; padding-bottom: 20px">
                <v-autocomplete
                  dense
                  v-model="workerSelected"
                  :items="workerList"
                  :label="$t('label.lbl_worker')"
                  :hint="setWorker()"
                  class="txt-single"
                  persistent-hint
                  :readonly="true"
                >
                  <!-- アイテム一覧の表示 -->
                  <template slot="item" slot-scope="data">
                    <span class="test">
                      {{ data.item.text }}
                    </span>
                  </template>
                  <!-- * -->
                </v-autocomplete>
              </div>
              <!-- カレンダー -->
              <!-- 指示日-->
              <div
                v-if="changeOkFlg == true"
                class="date-style"
                style="float: left; padding-left: 14px"
              >
                <v-text-field
                  outlined
                  dense
                  v-model="instructionDateFrom"
                  :label="$t('label.lbl_instructionDate')"
                  @change="changeDateFrom"
                  :rules="[rules.yyyymmdd]"
                  class="txt-single date-style"
                  clear-icon="mdi-close-circle"
                  clearable
                  :error-messages="alertDateMessage"
                ></v-text-field>
              </div>
              <div v-else class="date-style" style="float: left; padding-left: 14px">
                <v-text-field
                  outlined
                  dense
                  v-model="instructionDateFrom"
                  :label="$t('label.lbl_instructionDate')"
                  @change="changeDateFrom"
                  :rules="[rules.yyyymmdd]"
                  class="txt-single date-style"
                  :readonly="true"
                  :error-messages="alertDateMessage"
                ></v-text-field>
              </div>
              <span style="float: left" class="require">*</span>
              <div v-if="changeOkFlg == true" class="calender-btn-area" style="float: left">
                <v-menu
                  v-model="dateMenuFrom"
                  :close-on-content-click="false"
                  :nudge-right="-100"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  class="date-calendar"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" text x-small>
                      <v-icon>mdi-calendar</v-icon>
                    </v-btn>
                  </template>
                  <v-date-picker
                    v-model="instructionDateFromCal"
                    @input="dateMenuFrom = false"
                  ></v-date-picker>
                </v-menu>
                <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="prevDate" text x-small>
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextDate" text x-small>
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </div>
            </div>
            <!-- 備考欄-->
            <div
              v-if="changeOkFlg == true"
              class="search-autocomplete first-search-item"
              style="width: 480px; height: 100px"
            >
              <v-textarea
                outlined
                v-model="remarks"
                maxlength="200"
                no-resize
                :label="$t('label.lbl_remarksTextbox')"
                rows="3.8"
                :rules="[rules.remark]"
              />
            </div>
            <div
              v-else
              class="search-autocomplete first-search-item"
              style="width: 480px; height: 100px"
            >
              <v-textarea
                outlined
                v-model="remarks"
                maxlength="200"
                no-resize
                :label="$t('label.lbl_remarksTextbox')"
                rows="3.8"
                :readonly="true"
              />
            </div>
          </v-row>
          <v-row>
            <!--ボタン領域-->
            <div class="search-row-exeBtn pt-2">
              <!-- 在庫変更指示内容 -->
              <div style="float: left">
                <!-- 戻るボタン-->
                <v-btn color="primary" class="api-btn" @click="viewConfirmDialog('btn_back')">
                  {{ $t("btn.btn_back") }}
                </v-btn>
              </div>
              <div style="float: right">
                <!-- 更新ボタン-->
                <v-btn color="primary" class="api-btn" @click="viewConfirmDialog('btn_update')">
                  {{ $t("btn.btn_update") }}
                </v-btn>
              </div>
            </div>
            <div class="display-search-result-row"></div>
          </v-row>
          <v-row>
            <div class="display-search-result-row">
              <!--検索結果表示-->
              <div class="text-label" style="float: left">
                <span class="toatlNum">総件数：{{ totalCount }}件</span>
              </div>

              <!--表示件数切替部分-->
              <div style="float: right">
                <!--表示件数-->
                <div class="text-label" style="clear: both; float: left">
                  {{ $t("label.lbl_itemsPerPage") }}
                </div>
                <!-- 空白 -->
                <span class="item-spacer" style="float: left">&nbsp;</span>
                <!--表示件数切替ボタン-->
                <div style="float: left">
                  <v-btn-toggle
                    v-model="itemsPerPageBtn"
                    color="blue darken-2"
                    class="toggle black--text"
                    right
                  >
                    <v-btn class="pasing-btn" value="10" @click="u_10_on()" small>
                      {{ 10 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="50" @click="u_10_clear(50)" small>
                      {{ 50 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="100" @click="u_10_clear(100)" small>
                      {{ 100 }}
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </div>
            </div>
            <!-- ページング数 -->
          </v-row>
        </v-container>
      </v-form>
      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="listData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          :ripple="false"
          disable-filtering
          :items-per-page="itemsPerPage"
          :hide-default-footer="true"
          :page.sync="page"
          @page-count="pageCount = $event"
          height="380px"
        >
          <!-- ヘッダーロット -->
          <template v-slot:[`header.lot`]="{ header }">
            <span>
              <div>{{ header.text }}</div>
              <span>
                {{
                  $t("label.lbl_dueDate") +
                  "／" +
                  $t("label.lbl_lotSubkey1") +
                  "／" +
                  $t("label.lbl_lotSubkey2")
                }}
              </span>
            </span>
          </template>
          <!-- 品番/品名 -->
          <template v-slot:[`item.itemCdName`]="{ item }">
            <div class="align-left">{{ item.itemCd }}</div>
            <div class="align-left">{{ item.incidental }}</div>
            <div class="align-left">{{ item.itemName }}</div>
          </template>

          <!-- ステータス -->
          <template v-slot:[`item.status`]="{ item }">
            <div class="align-left">{{ item.status }}</div>
          </template>

          <!-- 倉庫 -->
          <template v-slot:[`item.warehouse`]="{ item }">
            <div class="align-left">{{ item.warehouse }}</div>
          </template>

          <!-- ロケ -->
          <template v-slot:[`item.location`]="{ item }">
            <div class="align-left">{{ item.location }}</div>
          </template>

          <!-- ロット -->
          <template v-slot:[`item.lot`]="{ item }">
            <div v-if="item.lot.length > 15 || item.dueDate.length > 15">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div class="text-overflow" style="width: 400px" v-bind="attrs" v-on="on">
                    {{ item.lot }}<br />
                    {{ item.dueDate }}
                  </div>
                </template>
                <div>{{ item.lot }}<br />{{ item.dueDate }}</div>
              </v-tooltip>
            </div>
            <div v-else>
              {{ item.lot }}<br />
              {{ item.dueDate }}
            </div>
          </template>

          <!-- 数量 -->
          <template v-slot:[`item.stockQty`]="{ item }">
            <div class="align-right">{{ item.stockQty }}</div>
          </template>

          <!--数量-->
          <template v-slot:[`item.inputQuantity`]="{ item, value, index }">
            <div
              v-if="
                item.completeFlg == '0' &&
                item.stockStateChangeDiv === '05' &&
                item.approvalFlg !== '1'
              "
            >
              <v-text-field
                v-model="item.inputQuantity"
                class="input-number"
                :value="value"
                outlined
                dense
                maxLength="22"
                @load="value = item.inputQuantity"
                :id="'inputQuantity' + index"
                autocomplete="off"
                :rules="[rules.isNumber, rules.isInteger, rules.isRangeCheck, rules.limitNumLength]"
              >
              </v-text-field>
            </div>
            <div v-else>
              <div class="align-right">{{ item.inputQuantity }}</div>
            </div>
          </template>
          <!--詳細マーク-->
          <!-- <template v-slot:[`item.detail`]="{ item, index }">
            <v-btn small @click="editdetail(item, index)" text v-if="instructionFlg == '05'">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template> -->
          <!--削除マーク-->
          <template v-slot:[`item.deleteData`]="{ item }">
            <v-btn v-if="item.completeFlg == '0'" small @click="deleteItem(item)" text>
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <!-- ヘッダーチェックボックス -->
          <template v-slot:[`header.check`]="{ header }">
            <input
              type="checkbox"
              :checked="isAllSelected"
              :value="header.check"
              @click="selectAllCheck"
              style="transform: scale(2)"
              :disabled="
                allCompleteFlf ||
                inputList.find((i) => {
                  return i.stockStateChangeDiv === '05' && i.approvalFlg === '1';
                }) !== void 0
              "
            />
          </template>
          <!-- 一覧チェックボックス -->
          <template v-slot:[`item.check`]="{ item, index }">
            <input
              type="checkbox"
              v-model="item.check"
              :value="listCheckbox"
              @click="checkList(item, index)"
              style="transform: scale(2)"
              :disabled="
                item.completeFlg == '1' ||
                (item.stockStateChangeDiv === '05' && item.approvalFlg === '1')
              "
            />
          </template>
        </v-data-table>
        <!-- ページング-->
        <div class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
        </div>
      </v-container>
      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <!-- 確認メッセージダイアログモーダル-->
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :screenFlag="ConfirmDialog.screenFlag"
        :firstPageFlag="infoDialog.firstPageFlag"
        :stockUpdateInstructionFlg="infoDialog.stockUpdateInstructionFlg"
      />
      <ConfirmDialog
        :isShow.sync="ConfirmDialog.isOpen"
        :message="ConfirmDialog.message"
        :okAction="ConfirmDialog.okAction"
        :redMessage="ConfirmDialog.redMessage"
        :screenFlag="ConfirmDialog.screenFlag"
        :changeFlag="ConfirmDialog.changeFlag"
      />
      <v-dialog v-model="updateDialogMessage" :max-width="800">
        <!-- 警告ダイアログ -->
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ backMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="updateOk"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- 詳細設定モーダル -->
      <v-dialog v-model="isOpenEditDetailDialog" :max-width="1300" persistent>
        <editDetailDateDialog
          :editDetailDateDialog.sync="isOpenEditDetailDialog"
          :viewDataList="inputList"
          :selectedIndex="selectedIndex"
        />
      </v-dialog>
    </v-sheet>
  </div>
</template>

<script>
import Loading from "@/components/loading";
import sideMenu from "@/components/Menu";
import SimpleDialog from "@/components/SimpleDialog";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { i18n } from "../../lang/lang.js";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import NavBar from "../../components/NavBar.vue";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import ConfirmDialog from "@/components/ConfirmDialog";

export default {
  name: appConfig.SCREEN_ID.P_SRG_003,
  components: {
    Loading,
    sideMenu,
    SimpleDialog,
    ConfirmDialog,
    NavBar,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  data: () => ({
    checkFlg: false,
    // 検索条件タイトル
    searchConditionTitle: "<検索内容>",
    // 登録画面タイトル
    insertScreenTitle: "<更新内容>",
    // 一覧選択チェックボックス
    listCheckbox: [],
    isAllSelected: false,
    //1ページあたりの表示件数
    itemsPerPage: 100,
    itemsPerPageBtn: "100",
    //初期ページ
    page: 1,
    //全ページ数
    pageCount: 0,
    // 編集用チェックボックスリスト
    chengeListCheckbox: [],
    listCheckboxBtn: [],
    loadingCounter: 0,
    // 承認・未承認
    isConfirmed: "2",
    // 検索ボタンをクリックした時に挙動を変える
    isActive: 1,
    // 承認ボタン活性化
    ableConfirm: true,
    // データ作成ボタン活性化
    confirmDone: true,
    // ダウンロードボタン活性化
    createDone: true,
    dialog: false,
    // 取引先
    suppliersSelected: "",
    // メニュー
    openMenu: null,
    isOpenEditDetailDialog: false,
    // 選択行
    selectedIndex: 0,
    // 総件数
    totalCount: 0,
    // 指示日From
    instructionDateFromCal: "",
    instructionDateFrom: "",
    instructionDate: "",
    dateMenuFrom: false,
    // カレンダー
    dateMenu: false,
    dateMenu2: false,
    returnURL: "",
    // 指示No
    txt_instructionNo: "",
    // 変更後品番・品名
    changedItemCdSelected: "",
    changedItemCdList: [],
    alertChangedItemCdMessage: "",
    afterItemCdSearch: "",
    changedItemCd: "",
    // 指示内容
    instructionContentSelected: "",
    // 作業者
    workerSelected: "",
    // ステータス
    changeStatusSelected: "",
    statusList: [],
    // エラーメッセージ
    alertMessage: "",
    alertInstructionNoMessage: "",
    alertItemCdMessage: "",
    alertInstructionContentMessage: "",
    alertWorkerMessage: "",
    alertDateMessage: "",
    alertSupplierMessage: "",
    // ヘッダ
    supplierList: [],
    instructionContentList: [],
    workerList: [],
    instructionNoList: [],
    itemCdList: [],
    // 一覧
    inputList: [],
    tmpList: [],
    viewData: {},
    tmpViewData: {},
    lengthBefor: "",
    lengthAfter: "",
    // 削除リスト
    deleteList: [],
    // 一覧index
    editedIndex: -1,
    // 確認メッセージダイアログ
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      stockUpdateInstructionFlg: false,
      firstPageFlag: false,
      thirdPageFlag: false,
      screenFlag: false,
    },
    // 取引先(text)
    supplierNm: "",
    // 品番(text)
    itemCd: "",
    // 指示内容(text)
    instructionContent: "",
    // 変更理由
    reasonChangeList: [],
    reasonChangeSelected: "",
    // 無償・有償
    isPaid: "01",
    // 無償理由
    freeReasonSelected: "",
    freeReasonList: [],
    isDisabledFreeReason: true,
    // 変更後ロット
    changedLot: "",
    alertChangedLot: "",
    // 変更後名義(取引先)
    changeSupplier: "",
    changeSupplierList: [],
    alertChangedSupplierMessage: "",
    // 備考欄
    remarks: "",
    // 有償無償
    freeCostDivList: [],
    // 戻るボタンダイアログ
    updateDialogMessage: false,
    // 戻るボタンダイアログメッセージ
    backMessage: "",
    // 付帯情報ダイアログ
    dialogAddProudct: false,
    ConfirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
      okAction: () => {},
    },
    // 明細フォーマット
    instructionFlg: "",
    searchParam: "",
    // 完了フラグ
    changeOkFlg: true,
    allCompleteFlf: false,
    // ソート
    sortItem: "",
    sortOptions: {},
    // 品番入力ヒント
    changedProductHint: "",
    // 変更後品名
    changeItemName: "",
    // 名義変更：品番リスト
    itemList: [],
    itemCdAfter: "",
    itemSidAfter: "",
    // ヘッダ
    headerItems: [
      {
        text: "選択",
        value: "check",
        width: "3%",
        align: "center",
        sortable: false,
      },
      // 品番/品名
      {
        text: i18n.tc("label.lbl_productCnCd"),
        value: "itemCdName",
        width: "20%",
        align: "left",
      },
      // ステータス
      {
        text: i18n.tc("label.lbl_status"),
        value: "status",
        width: "10%",
        align: "left",
        sortable: true,
      },
      // 倉庫
      {
        text: i18n.tc("label.lbl_warehouse"),
        value: "warehouse",
        width: "10%",
        align: "left",
        sortable: true,
      },
      // ロケ
      {
        text: i18n.tc("label.lbl_location"),
        value: "location",
        width: "10%",
        align: "left",
        sortable: true,
      },
      // ロット
      {
        text: i18n.tc("label.lbl_lot") + "／" + i18n.tc("label.lbl_serial"),
        value: "lot",
        width: "20%",
        align: "left",
        sortable: true,
      },
      // 入庫日
      {
        text: i18n.tc("label.lbl_enteringDate"),
        value: "yyyymmdd",
        width: "7%",
        align: "left",
        sortable: true,
      },

      // 数量
      {
        text: i18n.tc("label.lbl_quantity"),
        value: "stockQty",
        width: "7%",
        align: "right",
        sortable: true,
      },
      // 変更数量
      {
        text: i18n.tc("label.lbl_quantityChange"),
        value: "inputQuantity",
        width: "8%",
        align: "right",
        sortable: true,
        class: "asta",
      },
      // 削除
      { text: i18n.tc("btn.btn_delete"), value: "deleteData", width: "5%", align: "right" },
    ],
    // バリデーション
    rules: {
      yyyymmdd: (value) =>
        (dateTimeHelper.validDate(commonUtil.zen2han(value), "/") &&
          dateTimeHelper.isDate(value)) ||
        i18n.tc("check.chk_inputDate"),
      isNumber: (value) =>
        (!!value && !isNaN(Number(commonUtil.zen2han(value).replaceAll(",", "")))) ||
        i18n.tc("check.chk_inputNumber"),
      isInteger: (value) =>
        Number.isInteger(Number(commonUtil.zen2han(value).replaceAll(",", ""))) ||
        i18n.tc("check.chk_inputNotDecimal"),
      isRangeCheck: (value) =>
        1 <= Number(commonUtil.zen2han(value).replaceAll(",", "")) ||
        i18n.tc("check.chk_inputMore"),
      limitNumLength: (value) =>
        value == null ||
        String(value).replaceAll(",", "").length < 10 ||
        i18n.tc("check.chk_limitNumLength"), // 文字数の制約
      inputRequired: (value) => !!value || i18n.tc("check.chk_input"),
      remark: function (value) {
        if (value !== undefined && value !== null && value !== "") {
          value = String(value);
          if (value.replace(/\r?\n/g, "").length > 200) {
            return i18n.tc("check.chk_input_200");
          }
        }
        return true;
      },
    },
  }),
  methods: {
    u_10_on() {
      this.itemsPerPage = 10;
      this.page = 1;
    },
    u_10_clear(val) {
      this.itemsPerPage = val;
      this.page = 1;
    },
    // 初期化
    init() {
      this.inputList = [];
      this.deleteList = [];
      this.changedItemCdList = [];
      // 一覧画面からパラメータを受け取る
      let detailConfig = this.$route.params.detailSearchParam;
      this.searchParam = this.$route.params.detailSearchParam;
      if (detailConfig != null) {
        // ヘッダー検索内容
        this.instructionContent = detailConfig.instructionContentNm;
        this.instructionFlg = detailConfig.instructionFlg;
        this.supplierNm = detailConfig.searchSupplierNm;
        this.txt_instructionNo = detailConfig.instructionNo;
        this.instructionDateFrom = dateTimeHelper
          .convertJST(detailConfig.instructionDateTime)
          .substr(0, 10);
        this.instructionDateFromCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertJST(detailConfig.instructionDateTime)
        );
        this.instructionDate = dateTimeHelper
          .convertJST(detailConfig.instructionDateTime)
          .substr(0, 10);
        this.changeStatusSelected = detailConfig.qualityDivAfter;
        this.reasonChangeSelected = detailConfig.changeReasonDiv;
        this.changedLot = detailConfig.lotNoAfter.substring(0, 20).trimRight();
        this.changeSupplier = detailConfig.changeSupplier;
        this.suppliersSelected = detailConfig.searchSuppliersSelected;
        this.changedItemCd = detailConfig.changeItemCd;
        this.changedItemCdSelected = detailConfig.changeItemSid;
        this.changeItemName = detailConfig.changeItemName;

        this.isPaid = detailConfig.freeCostDiv;
        this.freeReasonSelected = detailConfig.freeCostReasonDiv;
        this.workerSelected = detailConfig.userSid;
        this.remarks = detailConfig.remarks;

        let dtList = detailConfig.filteredList;
        if (dtList != null) {
          for (var i = 0; i < dtList.length; i++) {
            let lotNoObj = commonUtil.convertLotNo(dtList[i].lotNoBefore);
            let item = {
              itemCdShow: dtList[i].itemCdBefore,
              itemCd: dtList[i].itemCdBefore.substring(0, 50).trimRight(),
              incidental: dtList[i].itemCdBefore.slice(-50).trimRight(),
              itemName: dtList[i].itemNameBefore,
              status: dtList[i].qualityNameBefore,
              warehouse: dtList[i].warehouseNameBefore,
              location: dtList[i].locationNoBefore,
              lotShow: dtList[i].lotNoBefore,
              lot: lotNoObj.lot,
              serial: dtList[i].lotNoBefore.slice(-80).trimRight(),
              dueDate: lotNoObj.dueDate,
              yyyymmdd: this.isBlank(dtList[i].yyyymmddHhmmss)
                ? ""
                : dateTimeHelper.convertUTC2JST(dtList[i].yyyymmddHhmmss).substr(0, 10),
              stockQty: commonUtil.formatToCurrency(Number(dtList[i].stockBefore)),
              inputQuantity: commonUtil.formatToCurrency(Number(dtList[i].quantity)),
              inputQuantityBefore: commonUtil.formatToCurrency(Number(dtList[i].quantity)),
              itemManageNo: dtList[i].itemManageNo,
              warehouseSid: dtList[i].warehouseSid,
              locationSid: dtList[i].locationSid,
              itemStandardSid: dtList[i].itemStandardSid,
              stockStateChangeSid: dtList[i].stockStateChangeSid,
              updateDatetime: dtList[i].updateDatetime,
              deviceId: dtList[i].deviceId,
              instDate: dtList[i].instDate,
              stockStateChangeDiv: dtList[i].stockStateChangeDiv,
              stockStateChangeNo: dtList[i].stockStateChangeNo,
              changeReasonDiv: dtList[i].changeReasonDiv,
              freeCostDiv: dtList[i].freeCostDiv,
              freeCostReasonDiv: dtList[i].freeCostReasonDiv,
              userSid: dtList[i].userSid,
              remarks: dtList[i].remarks,
              qualityDiv: dtList[i].qualityDiv,
              beforeQualityDiv: dtList[i].qualityDivBefore,
              clientSidAfter: dtList[i].clientSidAfter,
              clientSidBefore: dtList[i].clientSidBefore,
              itemStandardSidAfter: dtList[i].itemStandardSidAfter,
              itemCdAfter: dtList[i].itemCdAfter,
              lotNoAfter: dtList[i].lotNoAfter,
              completeFlg: dtList[i].completeFlg,
              check: false,
              approvalFlg: dtList[i].approvalFlg,
            };
            this.inputList.push(item);
            this.totalCount = this.inputList.length;
            let cnt = 0;
            for (var j = 0; j < this.inputList.length; j++) {
              if (0 < this.inputList[j].completeFlg == "1".length) {
                this.changeOkFlg = false;
              }
              if (this.inputList[j].completeFlg == "1") {
                cnt = cnt + 1;
              }
            }
            if (cnt === this.inputList.length) {
              this.allCompleteFlf = true;
            } else {
              this.allCompleteFlf = false;
            }
            this.tmpViewData = JSON.parse(JSON.stringify(this.inputList));
            this.lengthBefor = this.inputList.length;
            this.lengthAfter = this.inputList.length;
          }
        } else {
          this.loadingCounter = 0;
        }
      }

      // マスタ取得
      this.getMstCode();
      // 作業者
      this.getWorker();
      // 名義取得
      this.getCustomInfo();
    },
    updateOk() {
      this.updateDialogMessage = false;
    },
    /**
     * 初期データ取得
     */
    getCustomInfo() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // 取引先
      const clientList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertUTC()
      );
      // 作業者
      const userBizList = getParameter.getUserBiz();
      Promise.all([clientList, userBizList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.supplierList = result[0];
          let list = this.supplierList.filter((val) => {
            return val.value != this.suppliersSelected;
          });
          this.changeSupplierList = list;
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    /**
     * 全選択チェックボックス処理
     */
    selectAllCheck() {
      if (!this.isAllSelected) {
        this.listCheckbox = [];
        this.listCheckbox = true;
        this.isAllSelected = true;
        for (var i = 0; i < this.inputList.length; i++) {
          if (this.inputList[i].completeFlg == "0") {
            this.inputList[i].check = true;
            this.listCheckboxBtn.push(this.inputList[i]);
          } else {
            this.inputList[i].check = false;
          }
        }
      } else {
        this.listCheckbox = [];
        this.listCheckbox = false;
        for (var j = 0; j < this.inputList.length; j++) {
          this.inputList[j].check = false;

          // 画面遷移用リストを初期化
          this.listCheckboxBtn = [];
        }
        this.isAllSelected = false;
      }
    },
    /**
     * コードマスタ取得
     */
    getMstCode() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 理由コード
      const reasonList = getParameter.getCodeMst(appConfig.CODETYPE.IN_REASON_DIV);
      // 処理区分
      const processList = getParameter.getCodeMst(appConfig.CODETYPE.IN_PROCESS_DIV);
      // 有償無償区分
      const freeCostList = getParameter.getCodeMst(appConfig.CODETYPE.IN_FREECOST_DIV);
      // ステータス区分
      const statusCostList = getParameter.getCodeMst(appConfig.CODETYPE.IN_QUALITY_DIV);
      // 変更理由
      const reasonChagedList = getParameter.getCodeMst(appConfig.CODETYPE.INSTRUCTION_REASON);
      Promise.all([reasonList, processList, freeCostList, statusCostList, reasonChagedList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.freeReasonList = result[0];
          this.processDivList = result[1];
          this.freeCostDivList = result[2];
          this.statusList = result[3];
          this.reasonChangeList = result[4];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    /**
     * ボタン押下時の処理
     */
    viewConfirmDialog(button) {
      let isValidationRequired;
      let message;
      let action = () => {};
      let k = 0;
      switch (button) {
        case "btn_update":
          this.alertMessage = "";
          this.alertDateMessage = "";
          this.alertChangedLot = "";
          this.alertChangedSupplierMessage = "";
          this.alertChangedItemCdMessage = "";
          if (this.lengthAfter == this.lengthBefor) {
            let message = (this.backMessage = messsageUtil.getMessage("P-EXT-003_001_E"));
            if (this.listCheckboxBtn.length == 0) {
              this.updateDialogMessage = true;
              return message;
            }
          }
          // 必須チェック（更新場合のみ）
          if (this.listCheckboxBtn.length > 0) {
            if (
              this.changeStatusSelected == undefined ||
              this.changeStatusSelected == null ||
              this.changeStatusSelected == ""
            ) {
              this.alertMessage = i18n.tc("check.chk_input");
              return;
            }
            if (this.changeOkFlg == true) {
              // 現在日付を編集
              var str = dateTimeHelper.convertJST().substr(0, 10);
              // 必須チェック（更新場合のみ）
              if (this.instructionDateFrom != this.instructionDate) {
                if (new Date(this.instructionDateFrom).getTime() < new Date(str).getTime()) {
                  this.alertDateMessage = i18n.tc("check.chk_instructionDate");
                  return;
                }
              }
              // 必須チェック：変更後
              if (this.instructionFlg == "01") {
                // ステータス変更の場合
                if (this.changeStatusSelected == null || this.changeStatusSelected == "") {
                  this.alertMessage = i18n.tc("check.chk_input");
                  return;
                }
              } else if (this.instructionFlg == "04") {
                // 品番変更の場合
                if (this.changedItemCdSelected == null || this.changedItemCdSelected == "") {
                  this.alertChangedItemCdMessage = i18n.tc("check.chk_input");
                  return;
                }
              } else if (this.instructionFlg == "03") {
                // 名義変更の場合
                if (this.changeSupplier == null || this.changeSupplier == "") {
                  this.alertChangedSupplierMessage = i18n.tc("check.chk_input");
                  return;
                }
                this.getItemCd();
              } else {
                // 処理しない
              }
            }

            // 変更後数量チェック
            for (var i = 0; i < this.inputList.length; i++) {
              if (this.inputList[i].check) {
                var checkInputQuantity = Number(
                  commonUtil.zen2han(this.inputList[i].inputQuantity).replaceAll(",", "")
                );
                var checkStockQty = Number(
                  commonUtil.zen2han(this.inputList[i].stockQty).replaceAll(",", "")
                );
                if (checkInputQuantity > checkStockQty) {
                  this.updateDialogMessage = true;
                  this.backMessage = messsageUtil.getMessage("P-STC-001_002_E");
                  return;
                }
                if (this.instructionFlg == "01") {
                  // ステータスチェック
                  if (this.changeStatusSelected == this.inputList[i].beforeQualityDiv) {
                    this.updateDialogMessage = true;
                    this.backMessage = messsageUtil.getMessage("P-STC-001_003_E");
                    return;
                  }
                } else if (this.instructionFlg == "02") {
                  // ロットチェック
                  if (this.changedLot == this.inputList[i].lot) {
                    this.updateDialogMessage = true;
                    this.backMessage = messsageUtil.getMessage("P-STC-001_004_E");
                    return;
                  }
                } else if (this.instructionFlg == "04") {
                  // 品番チェック
                  if (this.changedItemCdSelected == this.inputList[i].itemStandardSid) {
                    this.updateDialogMessage = true;
                    this.backMessage = messsageUtil.getMessage("P-STC-001_005_E");
                    return;
                  }
                } else {
                  // 処理なし
                }

                if (checkInputQuantity == 0) {
                  // 数量が0の場合、警告メッセージ表示
                  k = i + 1;
                } else {
                  // 処理なし
                }

                if (checkInputQuantity == "") {
                  // 数量が未入力時
                  k = i + 1;
                } else {
                  // 処理なし
                }

                // 数値チェック
                if (isNaN(checkInputQuantity)) {
                  // 数量が数値でない時
                  k = i + 1;
                } else {
                  // 処理なし
                }

                // 整数チェック
                if (Number.isInteger(checkInputQuantity)) {
                  // 処理なし
                } else {
                  // 数量が整数でない時
                  k = i + 1;
                }

                // 範囲チェック（0未満）
                if (0 < checkInputQuantity) {
                  // 処理なし
                } else {
                  // 数量が0未満の場合
                  k = i + 1;
                }
                if (k != 0) {
                  let checkPosition = String(i);
                  if (!this.inputList[i].checkInputQuantity) {
                    // 関係ないレコードの入力チェックエラーを解除
                    eval("this.$refs.checkInputQuantity" + checkPosition + ".resetValidation()");
                  } else {
                    // 処理なし
                  }
                }
              }
            }
          }
          // if (!this.isDataUpdated()) {
          //   return;
          // }
          isValidationRequired = true;
          message = "P-RCV-001_003_C";
          action = this.registration;
          break;
        case "btn_back":
          isValidationRequired = false;
          message = "P-COM-001_004_W";
          action = this.back;
          break;
        default:
          isValidationRequired = true;
          message = "";
      }
      if (isValidationRequired) {
        //   let isValid = this.$refs.form.validate();
        //   if (!isValid) {
        //     this.scrollToErrorRow();
        //     return;
        //   }
      }
      this.ConfirmDialog.message = messsageUtil.getMessage(message);
      this.ConfirmDialog.title = appConfig.DIALOG.title;
      this.ConfirmDialog.isOpen = true;
      this.ConfirmDialog.screenFlag = true;
      this.ConfirmDialog.changeFlag = false;
      this.ConfirmDialog.okAction = action;
    },
    // イベント終了ダイアログ表示
    endEvent(button) {
      this.deleteList = [];
      this.infoDialog.message = messsageUtil.getMessage("P-COM-001_005_C", [i18n.tc(button)]);
      this.infoDialog.title = "結果";
      this.infoDialog.isOpen = true;
      this.infoDialog.screenFlag = true;
      this.infoDialog.stockUpdateInstructionFlg = true;
    },
    /**
     * オブジェクトをソートする
     */
    objectSort(obj) {
      if (obj == void 0 || obj == null) {
        return obj;
      }
      let entries = Object.entries(obj);
      if (entries == void 0 || entries == null) {
        return entries;
      }
      const sorted = entries.sort();
      for (let i in sorted) {
        const val = sorted[i][1];
        if (typeof val === "object") {
          sorted[i][1] = this.objectSort(val);
        }
      }
      return sorted;
    },
    // 担当者プルダウン
    getWorker() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // 担当者
      const config = this.$httpClient.createGetApiRequestConfig();
      config.params.searchCategoryDiv = "0";

      // console.debug("getManager() Config", config);
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.MST_USERBIZ, config)
          .then((response) => {
            // console.debug("getManager() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              const list = [];
              jsonData.resIdv.users.forEach((row) => {
                list.push({
                  text: row.userName,
                  value: row.userSid,
                });
              });

              // 画面の初期値を設定します。
              this.workerList = list;

              resolve(response);
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;

              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;

            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    /**
     * 指示日付Fromを－１日します。
     */
    prevDate() {
      if (this.instructionDateFromCal == null) {
        return;
      }
      let date = new Date(this.instructionDateFromCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.instructionDateFromCal != toDate) {
        this.instructionDateFromCal = toDate;
      } else {
        this.instructionDateFromCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
      }
    },

    /**
     * 指示日付Fromを＋１日します。
     */
    nextDate() {
      if (this.instructionDateFromCal == null) {
        return;
      }
      let date = new Date(this.instructionDateFromCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.instructionDateFromCal != toDate) {
        this.instructionDateFromCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 2));
      } else {
        this.instructionDateFromCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
      }
    },

    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },

    /**
     * 日付入力(From)
     */
    changeDateFrom(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.instructionDateFromCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.instructionDateFromCal = null;
      }
    },
    /**
     * URL発行
     */
    link() {
      location.href = this.returnURL;
    },

    /**
     * 取引先コードリストボックスセット処理
     */
    setSrhSupplier() {
      for (var i = 0; i < this.changeSupplierList.length; i++) {
        if (this.changeSupplierList[i].value == this.changeSupplier) {
          return this.changeSupplierList[i].name;
        }
      }
    },
    /**
     * 品番リストセット処理
     */
    setItemCd() {
      for (var i = 0; i < this.changeItemCdNameList.length; i++) {
        if (this.changeItemCdNameList[i].value == this.changeItemCdNameSelect) {
          return this.changeItemCdNameList[i].name;
        }
      }
    },
    /**
     * プルダウンチェンジイベント
     */
    changeProductNm(value) {
      // 既存品番/品名を選択時
      let val = this.changeItemCdNameList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.productHint = val.text;
        this.search = val.text;
      }
      this.changeItemCdNameList = this.changeItemCdNameList.filter((v) => v.value == value);
    },

    /**
     * 指示内容リストセット処理
     */
    setInstructionContent() {
      for (var i = 0; i < this.instructionContentList.length; i++) {
        if (this.instructionContentList[i].value == this.instructionContentSelected) {
          return this.instructionContentList[i].name;
        }
      }
    },
    /**
     * 作業者リストセット処理
     */
    setWorker() {
      for (var i = 0; i < this.workerList.length; i++) {
        if (this.workerList[i].value == this.workerSelected) {
          return this.workerList[i].name;
        }
      }
    },
    /**
     * 変更理由リストセット処理
     */
    setReasonChange() {
      for (var i = 0; i < this.reasonChangeList.length; i++) {
        if (this.reasonChangeList[i].value == this.reasonChangeSelected) {
          return this.reasonChangeList[i].name;
        }
      }
    },
    /**
     * 変更後ステータスリストセット処理
     */
    changeStatus() {
      for (var i = 0; i < this.statusList.length; i++) {
        if (this.statusList[i].value == this.changeStatusSelected) {
          return this.statusList[i].name;
        }
      }
    },
    /**
     * 変更エリア：品番リストセット処理
     */
    setItemCdChange() {
      for (var i = 0; i < this.changedItemCdList.length; i++) {
        if (this.changedItemCdList[i].value == this.changedItemCdSelected) {
          return this.changedItemCdList[i].name;
        }
      }
    },
    /**
     * 変更後品番：品番取得
     */
    getItemCdChange() {
      for (var i = 0; i < this.changedItemCdList.length; i++) {
        if (this.changedItemCdList[i].value == this.changedItemCdSelected) {
          return this.changedItemCdList[i].code;
        }
      }
    },
    /**
     * 変更エリア：品名取得プルダウンチェンジイベント
     */
    afterChangedProductNm(value) {
      // 既存品番/品名を選択時
      let val = this.changedItemCdList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.changedProductHint = val.text;
        this.changedItemCd = val.code;
        this.afterItemCdSearch = val.text;
      }
      this.changedItemCdList = this.changedItemCdList.filter((v) => v.value == value);
    },
    /**
     * 戻るボタン処理
     */
    back() {
      // 在庫情報変更指示一覧へ遷移
      this.$router.push({
        name: appConfig.SCREEN_ID.P_STC_001,
        params: {
          detailSearchParam: this.searchParam,
          backPage: "StockUpdateInstruction",
        },
      });
    },
    /**
     * チェックボックス押下処理
     */
    checkList(item, index) {
      if (!item.check) {
        // チェックを入れた場合
        this.inputList[index].check = true;

        // 編集用リスト
        this.chengeListCheckbox.push(item);

        // 画面遷移用リスト
        this.listCheckboxBtn.push(item);
      } else {
        // チェックを外した場合
        this.inputList[index].check = false;

        // 画面遷移用リストを初期化
        this.listCheckboxBtn = [];

        // チェックを外したレコード以外を画面遷移用リストに詰めなおす
        for (var i = 0; i < this.inputList.length; i++) {
          if (this.inputList[i].check != false) {
            this.listCheckboxBtn.push(this.inputList[i]);
          }
        }

        // 編集用リストも更新
        this.chengeListCheckbox = [];
        for (var j = 0; j < this.listCheckboxBtn.length; j++) {
          this.chengeListCheckbox.push(this.listCheckboxBtn[j]);
        }
        if (this.listCheckboxBtn.length < this.inputList.length) {
          this.isAllSelected = false;
        }

        if (this.listCheckboxBtn.length == 0 && this.chengeListCheckbox.length == 0) {
          this.isAllSelected = false;
        }
      }
    },
    /**
     * 行削除処理
     */
    deleteItem(item) {
      this.editedIndex = this.inputList.indexOf(item);

      // 削除したリストを退避する。
      this.inputList[this.editedIndex].deleteFlg = "1";
      this.deleteList.push(this.inputList[this.editedIndex]);
      this.inputList.splice(this.editedIndex, 1);
      // 削除後に件数を取得
      this.lengthAfter = this.inputList.length;

      for (var i = 0; i < this.inputList.length; i++) {
        this.inputList[i].No = i + 1;
      }
    },
    /**
     * 更新処理
     */
    registration() {
      this.alertMessage = "";
      this.alertDateMessage = "";
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // APIパラメータ
      const body = this.$httpClient.createRequestBodyConfig();
      // 画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_STC_002;
      // 修正ﾘｽﾄ抽出
      var bodyList = [];
      // 更新リスト作成
      for (var i = 0; i < this.inputList.length; i++) {
        if (this.inputList[i].completeFlg == "0") {
          var inputMap = {};
          if (this.inputList[i].check) {
            // 変更後数量
            inputMap.stockAfter = this.inputList[i].inputQuantity.replaceAll(",", "");
          } else {
            // 変更後数量
            inputMap.stockAfter = this.inputList[i].inputQuantityBefore.replaceAll(",", "");
          }
          // 処理区分
          inputMap.procDiv = "2";
          // 変更後品番SID
          if (this.instructionFlg == "04") {
            inputMap.itemStandardSidAfter = this.changedItemCdSelected;
          } else if (this.instructionFlg == "03") {
            inputMap.itemStandardSidAfter = this.itemSidAfter;
          } else {
            inputMap.itemStandardSidAfter = this.inputList[i].itemStandardSid;
          }
          // 変更後品番
          // inputMap.itemCdAfter = this.inputList[i].itemCdAfter;
          if (this.instructionFlg == "04") {
            inputMap.itemCdAfter = this.getItemCdChange();
          } else {
            inputMap.itemCdAfter = this.inputList[i].itemCdShow;
          }
          // 変更後ロット
          if (this.instructionFlg == "02") {
            if (this.changedLot != null && this.changedLot != "") {
              inputMap.lotNoAfter =
                this.changedLot.padEnd(20, " ") + this.inputList[i].serial.padEnd(80, " ");
            } else {
              inputMap.lotNoAfter = "".padEnd(20, " ") + this.inputList[i].serial.padEnd(80, " ");
            }
          } else {
            inputMap.lotNoAfter = this.inputList[i].lotShow;
          }
          // 在庫状態変更指示SID
          inputMap.stockStateChangeSid = this.inputList[i].stockStateChangeSid;
          // 在庫状態変更No
          if (this.txt_instructionNo) {
            inputMap.stockStateChangeNo = this.txt_instructionNo;
          } else {
            inputMap.stockStateChangeNo = this.inputList[i].stockStateChangeNo;
          }
          // 指示日
          if (this.instructionDateFrom) {
            inputMap.instDate = dateTimeHelper.convertUTC(this.instructionDateFrom);
          } else {
            inputMap.instDate = this.inputList[i].instDate;
          }
          // 変更理由区分
          if (this.reasonChangeSelected) {
            inputMap.changeReasonDiv = this.reasonChangeSelected;
          } else if (
            this.inputList[i].changeReasonDiv == null ||
            this.reasonChangeSelected == "" ||
            this.reasonChangeSelected == null
          ) {
            inputMap.changeReasonDiv = "";
          } else {
            inputMap.changeReasonDiv = this.inputList[i].changeReasonDiv;
          }
          // 無償理由区分
          if (this.freeReasonSelected) {
            inputMap.freeCostReasonDiv = this.freeReasonSelected;
          } else if (
            this.inputList[i].freeCostReasonDiv == null ||
            this.freeReasonSelected == "" ||
            this.freeReasonSelected == null
          ) {
            inputMap.freeCostReasonDiv = "";
          } else {
            inputMap.freeCostReasonDiv = this.inputList[i].freeCostReasonDiv;
          }
          // 有償無償区分
          if (this.isPaid) {
            inputMap.freeCostDiv = this.isPaid;
          } else if (
            this.inputList[i].freeCostDiv == null ||
            this.isPaid == "" ||
            this.isPaid == null
          ) {
            inputMap.freeCostDiv = "";
          } else {
            inputMap.freeCostDiv = this.inputList[i].freeCostDiv;
          }
          // 品質区分
          // inputMap.qualityDivAfter = this.changeStatusSelected;
          // 変更前品質区分
          inputMap.qualityDivBefore = this.inputList[i].beforeQualityDiv;
          // 変更後品質区分
          if (this.instructionFlg == "01") {
            if (this.changeStatusSelected != null && this.changeStatusSelected != "") {
              inputMap.qualityDivAfter = this.changeStatusSelected;
            } else {
              // 処理しない
            }
          } else {
            inputMap.qualityDivAfter = this.inputList[i].beforeQualityDiv;
          }
          // 変更前取引先
          inputMap.clientSidBefore = this.inputList[i].clientSidBefore;
          // 変更後取引先SID
          if (this.instructionFlg == "03") {
            inputMap.clientSidAfter = this.changeSupplier;
          } else {
            inputMap.clientSidAfter = this.inputList[i].clientSidBefore;
          }

          // ユーザSID
          if (this.workerSelected) {
            inputMap.userSid = this.workerSelected;
          } else if (
            this.inputList[i].userSid == null ||
            this.workerSelected == "" ||
            this.workerSelected == null
          ) {
            inputMap.userSid = "";
          } else {
            inputMap.userSid = this.inputList[i].userSid;
          }
          // 備考
          if (this.remarks) {
            inputMap.remarks = this.remarks;
          } else if (
            this.inputList[i].remarks == null ||
            this.remarks == "" ||
            this.remarks == null
          ) {
            inputMap.remarks = "";
          } else {
            inputMap.remarks = this.inputList[i].remarks;
          }
          // 更新日時
          inputMap.updateDatetime = this.inputList[i].updateDatetime;
          // 在庫状態変更区分（指示内容区分）
          inputMap.stockStateChangeDiv = this.instructionFlg;
          bodyList.push(inputMap);
        } else {
          // 処理なし
        }
      }

      // 削除リスト作成
      for (var j = 0; j < this.deleteList.length; j++) {
        var deleteMap = {};
        // 処理区分
        deleteMap.procDiv = "3";
        // 在庫状態変更SID
        deleteMap.stockStateChangeSid = this.deleteList[j].stockStateChangeSid;
        // 更新日時
        deleteMap.updateDatetime = this.deleteList[j].updateDatetime;
        bodyList.push(deleteMap);
      }

      // 実績一覧に追加
      body.reqIdv.stockStateChanges = bodyList;
      // console.debug("registration() Body", body);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.BIZ_STOCK_INSTRUCTION_ADD, body, appConfig.APP_CONFIG)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // console.debug("registration() Response", response);

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.stockUpdateInstructionFlg = true;
              // リストクリア
              this.inputList = [];
              this.deleteList = [];
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.stockUpdateInstructionFlg = false;
              this.infoDialog.screenFlag = true;
              // OK NGボタン
              this.infoDialog.firstPageFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.stockUpdateInstructionFlg = false;
            this.infoDialog.firstPageFlag = true;
            this.infoDialog.screenFlag = true;
            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    // 名義変更の場合：変更後品番・品名取得
    getItemCd() {
      if (this.changedItemCd != null && this.changedItemCd != "") {
        // 取引先が選択されてない場合、処理しない
        if (this.changeSupplier == null || this.changeSupplier == "") {
          return;
        }
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        // if (val == this.changedProductHint) {
        //   return;
        // }
        // 入力桁数が定義した数異常の場合検索処理
        if (this.changedItemCd.length >= appConfig.CNTCHARITEM) {
          const itemsBiz = getParameter.getItemsBizAddName2(
            this.changeSupplier,
            dateTimeHelper.convertUTC(),
            this.changedItemCd
          );

          Promise.all([itemsBiz])
            .then((result) => {
              // 画面の初期値を設定します。
              const list = [];
              this.itemList = list.concat(result[0]);
              this.itemSidAfter = this.itemList[0].value;
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        } else {
          this.changedItemCdList.length = 0;
        }
      }
    },
    // 変更エリア：変更後品番・品名
    getChangedItemCd(val) {
      if (val != null && val != "") {
        // 取引先が選択されてない場合、処理しない
        if (this.suppliersSelected == null || this.suppliersSelected == "") {
          return;
        }
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.changedProductHint) {
          return;
        }
        // 入力桁数が定義した数異常の場合検索処理
        if (val.length >= appConfig.CNTCHARITEM) {
          const itemsBiz = getParameter.getItemsBizAddName2(
            this.suppliersSelected,
            dateTimeHelper.convertUTC(),
            val
          );

          Promise.all([itemsBiz])
            .then((result) => {
              // 画面の初期値を設定します。
              const list = [];
              this.changedItemCdList = list.concat(result[0]);
              this.changedItemCdList.unshift({ name: "", text: "", value: "" });
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        } else {
          this.changedItemCdList.length = 0;
        }
      }
    },
    isBlank(value) {
      return (value ?? "") === "";
    },
  },
  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    changeStatusSelected() {
      this.alertMessage = "";
    },
    suppliersSelected() {
      this.alertSupplierMessage = "";
    },
    afterItemCdSearch(val) {
      if (val == null && this.changedItemCd != null && this.changedItemCd != "") {
        // 商品情報取得API関数呼び出
        // val = this.changedItemCd;
        this.getChangedItemCd(this.changedItemCd);
        var itemCd = this.changedItemCd.substring(0, 50).trim();
        var incidental = this.changedItemCd.slice(-50).trim();
        this.changedProductHint = itemCd + " " + incidental + "（" + this.changeItemName + "）";
        return;
      } else {
        this.getChangedItemCd(val);
        this.changedProductHint = this.changedItemCd + "（" + this.changeItemName + "）";
        return;
      }
    },
    // 変更エリア：変更後品番・品名
    // afterItemCdSearch(val) {
    //   if (val ?? false) {
    //     // 取引先が選択されてない場合、処理しない
    //     if (this.suppliersSelected == null || this.suppliersSelected == "") {
    //       return;
    //     }
    //     // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
    //     if (val == this.changedProductHint) {
    //       return;
    //     }
    //     if (val == this.changedItemCdSelected) {
    //       return;
    //     }
    //     // 入力桁数が定義した数異常の場合検索処理
    //     if (val.length >= appConfig.CNTCHARITEM) {
    //       const itemsBiz = getParameter.getItemsBizAddName(
    //         this.suppliersSelected,
    //         dateTimeHelper.convertUTC(),
    //         val
    //       );

    //       Promise.all([itemsBiz])
    //         .then((result) => {
    //           // 画面の初期値を設定します。
    //           const list = [];
    //           this.changedItemCdList = list.concat(result[0]);
    //           this.changedItemCdList.unshift({ name: "", text: "", value: "" });
    //         })
    //         .catch((ex) => {
    //           this.infoDialog.message = ex;
    //           this.infoDialog.title = appConfig.DIALOG.title;
    //           this.infoDialog.isOpen = true;
    //         })
    //         .finally(() => {
    //           // ローディング画面表示OFF
    //           this.loadingCounter = 0;
    //         });
    //     } else {
    //       this.changedItemCdList.length = 0;
    //     }
    //   }
    // },
    instructionDateFromCal(val) {
      this.instructionDateFrom = this.formatDate(val);
      this.alertDateMessage = "";
    },
    isPaid: function (newVal) {
      if (newVal == "02") {
        this.isDisabledFreeReason = false;
      } else {
        this.isDisabledFreeReason = true;
        this.freeReasonSelected = "";
      }
    },
    sortOptions: {
      handler(items) {
        // console.debug("watch sortByApi", items);
        if (items.sortBy.length < 1) {
          this.sortItem = "";
        } else {
          var sortBy = items.sortBy[0];
          var ascdesc = items.sortDesc[0] ? " desc" : " asc";

          this.sortItem = appConfig.P_STC_003_SORT[sortBy] + ascdesc; // + ascdesc;

          if (sortBy == "warehouse") {
            this.inputList.sort((a, b) => {
              if (ascdesc == " asc") {
                if (a.warehouse < b.warehouse) {
                  return -1;
                } else if (a.warehouse > b.warehouse) {
                  return 1;
                } else {
                  return 0;
                }
              } else if (ascdesc == " desc") {
                if (a.warehouse > b.warehouse) {
                  return -1;
                } else if (a.warehouse < b.warehouse) {
                  return 1;
                } else {
                  return 0;
                }
              } else {
                // this.searchApi();
              }
            });
          } else if (sortBy == "location") {
            this.inputList.sort((a, b) => {
              if (ascdesc == " asc") {
                if (a.location < b.location) {
                  return -1;
                } else if (a.location > b.location) {
                  return 1;
                } else {
                  return 0;
                }
              } else if (ascdesc == " desc") {
                if (a.location > b.location) {
                  return -1;
                } else if (a.location < b.location) {
                  return 1;
                } else {
                  return 0;
                }
              } else {
                // this.searchApi();
              }
            });
          } else if (sortBy == "status") {
            this.inputList.sort((a, b) => {
              if (ascdesc == " asc") {
                if (a.status < b.status) {
                  return -1;
                } else if (a.status > b.status) {
                  return 1;
                } else {
                  return 0;
                }
              } else if (ascdesc == " desc") {
                if (a.status > b.status) {
                  return -1;
                } else if (a.status < b.status) {
                  return 1;
                } else {
                  return 0;
                }
              } else {
                // this.searchApi();
              }
            });
          } else if (sortBy == "stockQty") {
            this.inputList.sort((a, b) => {
              if (ascdesc == " asc") {
                return a.stockQty - b.stockQty;
              } else {
                return b.stockQty - a.stockQty;
              }
            });
          } else if (sortBy == "lot") {
            this.inputList.sort((a, b) => {
              if (ascdesc == " asc") {
                if (a.lot < b.lot) {
                  return -1;
                } else if (a.lot > b.lot) {
                  return 1;
                } else {
                  return 0;
                }
              } else if (ascdesc == " desc") {
                if (a.lot > b.lot) {
                  return -1;
                } else if (a.lot < b.lot) {
                  return 1;
                } else {
                  return 0;
                }
              } else {
                // this.searchApi();
              }
            });
          } else if (sortBy == "yyyymmdd") {
            this.inputList.sort((a, b) => {
              if (ascdesc == " asc") {
                if (a.yyyymmdd < b.yyyymmdd) {
                  return -1;
                } else if (a.yyyymmdd > b.yyyymmdd) {
                  return 1;
                } else {
                  return 0;
                }
              } else if (ascdesc == " desc") {
                if (a.yyyymmdd > b.yyyymmdd) {
                  return -1;
                } else if (a.yyyymmdd < b.yyyymmdd) {
                  return 1;
                } else {
                  return 0;
                }
              } else {
                // this.searchApi();
              }
            });
          } else if (sortBy == "inputQuantity") {
            this.inputList.sort((a, b) => {
              if (ascdesc == " asc") {
                return a.inputQuantity - b.inputQuantity;
              } else if (ascdesc == " desc") {
                return b.inputQuantity - a.inputQuantity;
              } else {
                // this.searchApi();
              }
            });
          }
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}

.txt-singleComplete ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
  color: #f5f5f5;
}

.toatlNum {
  margin-left: 10px;
}

.text-overflow {
  width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

//備考欄、テキストボックス
.search-textbox-remark {
  width: 40rem;
}

textarea {
  resize: none;
}
</style>
