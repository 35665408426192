<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_stockUpdateInstructionList" />
      <v-form ref="form">
        <v-container fluid>
          <!--検索項目-->
          <v-row class="search-row">
            <!-- 取引先-->
            <div class="search-autocomplete first-search-item" style="float: left; width: 450px">
              <v-autocomplete
                dense
                v-model="suppliersSelected"
                :items="supplierList"
                :label="$t('label.lbl_supplier')"
                class="txt-single"
                :hint="setSrhSupplier()"
                persistent-hint
                :rules="[rules.supplierRequired]"
                pa-3
                :error-messages="alertSupplierMessage"
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </div>
            <span class="require">*</span>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 指示No -->
            <div class="serch-textbox-arrivalNo txt_instructionNo">
              <v-text-field
                outlined
                dense
                v-model="txt_instructionNo"
                maxlength="50"
                :label="$t('label.lbl_instructionNo')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- カレンダー -->
            <!-- 指示日From-->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="instructionDateFrom"
                :label="$t('label.lbl_instructionDate') + '(From)'"
                @change="changeDateFrom"
                :rules="[rules.yyyymmdd]"
                class="txt-single date-style"
                clear-icon="mdi-close-circle"
                clearable
              ></v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>
            <div class="calender-btn-area">
              <v-menu
                v-model="dateMenuFrom"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="instructionDateFromCal"
                  @input="dateMenuFrom = false"
                ></v-date-picker>
              </v-menu>
              <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="prevDate" text x-small>
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextDate" text x-small>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>

            <!-- カレンダーの間 -->
            <div class="from_to">~</div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 指示日To-->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="instructionDateTo"
                :label="$t('label.lbl_instructionDate') + '(To)'"
                @change="changeDateTo"
                :rules="[rules.yyyymmdd]"
                class="txt-single date-style"
                clear-icon="mdi-close-circle"
                clearable
                :error-messages="alertMessage"
              >
              </v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>
            <div class="calender-btn-area">
              <v-menu
                v-model="dateMenuTo"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="instructionDateToCal"
                  @input="dateMenuTo = false"
                ></v-date-picker>
              </v-menu>
              <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="prevToDate" text x-small>
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextToDate" text x-small>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <!-- カレンダー終わり -->
            <!-- v-row 1  終わり -->
          </v-row>
          <!--v-row2 始まり-->
          <v-row>
            <!-- 品番-->
            <div class="search-autocomplete first-search-item" style="float: left; width: 300px">
              <v-autocomplete
                dense
                v-model="itemCdSelected"
                :items="itemCdList"
                :label="$t('label.lbl_productCode')"
                :error-messages="alertItemCdMessage"
                @change="(event) => changeProductNm(event)"
                :search-input.sync="search"
                :hint="setItemCd()"
                class="txt-single"
                persistent-hint
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 指示内容-->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="instructionContentSelected"
                :items="instructionContentList"
                :label="$t('label.lbl_instructionContent')"
                :hint="setInstructionContent()"
                :rules="[rules.txt_instructionNoRequired]"
                :error-messages="alertInstructionContentMessage"
                class="txt-single"
                persistent-hint
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require">*</span>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 作業者 -->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="workerSelected"
                :items="workerList"
                :label="$t('label.lbl_worker')"
                :hint="setWorker()"
                persistent-hint
                class="txt-single"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 承認-->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="unApproval"
                :items="unApprovalList"
                :label="$t('btn.btn_approval')"
                :hint="setUnApproval()"
                class="txt-single"
                persistent-hint
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <!-- v-row 2  終わり -->
          </v-row>

          <v-row>
            <!--ボタン領域-->
            <div class="search-row-exeBtn">
              <!--検索ボタン領域-->
              <div class="btn-search-area" style="float: left">
                <!-- 検索ボタン -->
                <v-btn color="primary" class="api-btn" @click="searchApi(true)">{{
                  $t("btn.btn_search")
                }}</v-btn>
                <!--画面クリアボタン-->
                <v-btn class="other-btn" @click="clearScreen()">{{ $t("btn.btn_clear") }}</v-btn>
              </div>
              <div style="float: right">
                <!-- データ作成ボタン -->
                <v-btn
                  @click="download"
                  :disabled="isCanNotDownloadFlg"
                  color="primary"
                  class="api-btn"
                >
                  {{ $t("btn.btn_outPutData") }}
                </v-btn>
                <!-- 承認ボタン-->
                <v-btn
                  ref="btnInquiry"
                  color="primary"
                  :disabled="!['01', '05'].includes(instructionContentSelected)"
                  class="other-btn"
                  @click="approvalApi"
                >
                  {{ $t("btn.btn_approval") }}
                </v-btn>
                <!-- 新規登録ボタン-->
                <v-btn ref="btnInquiry" color="primary" class="other-btn" @click="goInitRegist">
                  {{ $t("btn.btn_iniRegist") }}
                </v-btn>
              </div>
            </div>
            <div class="display-search-result-row">
              <!--検索結果表示-->
              <div class="text-label" style="float: left">
                <span class="toatlNum">総件数：{{ totalCount }}件</span>
              </div>

              <!--表示件数切替部分-->
              <div style="float: right">
                <!--表示件数-->
                <div class="text-label" style="clear: both; float: left">
                  {{ $t("label.lbl_itemsPerPage") }}
                </div>
                <!-- 空白 -->
                <span class="item-spacer" style="float: left">&nbsp;</span>
                <!--表示件数切替ボタン-->
                <div style="float: left">
                  <v-btn-toggle
                    v-model="itemsPerPageBtn"
                    color="blue darken-2"
                    class="toggle black--text"
                    right
                  >
                    <v-btn class="pasing-btn" value="10" @click="u_10_on()" small>
                      {{ 10 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="50" @click="u_10_clear(50)" small>
                      {{ 50 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="100" @click="u_10_clear(100)" small>
                      {{ 100 }}
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </div>
            </div>
            <!-- ページング数 -->
          </v-row>
        </v-container>
      </v-form>
      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="listData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          :page.sync="page"
          :ripple="false"
          :server-items-length="itemsPerPage"
          disable-filtering
          :hide-default-footer="true"
          height="650px"
          :options.sync="sortOptions"
        >
          <!-- ヘッダーチェックボックス -->
          <template v-slot:[`header.check`]="{ header }">
            <input
              type="checkbox"
              :checked="isAllSelected"
              :value="header.check"
              @click="selectAllCheck"
              style="transform: scale(2)"
              :disabled="!['01', '05'].includes(instructionContentSelected)"
            />
          </template>
          <!-- 一覧チェックボックス -->
          <template v-slot:[`item.check`]="{ item, index }">
            <input
              type="checkbox"
              v-model="item.check"
              :value="listCheckbox"
              style="transform: scale(2)"
              @click="checkList(item, index)"
              :disabled="!['01', '05'].includes(instructionContentSelected)"
            />
          </template>
          <!-- 品番/品名 -->
          <template v-slot:[`item.itemCdName`]="{ item }">
            <div class="align-left">{{ item.itemCd }}</div>
            <div class="align-left">{{ item.incidental }}</div>
            <div class="align-left">{{ item.itemName }}</div>
          </template>
          <!--詳細マーク-->
          <template v-slot:[`item.detail`]="{ item, index }">
            <v-btn small @click="editdetail(item, index)" text>
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
        </div>
      </v-container>
      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <!-- 確認メッセージダイアログモーダル-->
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :firstPageFlag="infoDialog.firstPageFlag"
        :thirdPageFlag="infoDialog.thirdPageFlag"
      />
      <v-dialog v-model="updateDialogMessage" :max-width="800">
        <!-- 警告ダイアログ -->
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ backMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="updateOk"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- 詳細設定モーダル -->
      <v-dialog v-model="isOpenEditDetailDialog" :max-width="1300" persistent>
        <editDetailDateDialog
          :editDetailDateDialog.sync="isOpenEditDetailDialog"
          :viewDataList="inputList"
          :selectedIndex="selectedIndex"
        />
      </v-dialog>
      <ConfirmDialog
        :isShow.sync="ConfirmDialog.isOpen"
        :message="ConfirmDialog.message"
        :okAction="postApproval"
        :redMessage="ConfirmDialog.redMessage"
        :screenFlag="ConfirmDialog.screenFlag"
        :changeFlag="ConfirmDialog.changeFlag"
      />
      <CheckDialog
        :isShow.sync="checkDialog.isOpen"
        :message="checkDialog.message"
        :screenFlag="checkDialog.screenFlag"
        :okAction="checkDialog.okAction"
        :redMessage="checkDialog.redMessage"
        :changeFlag="checkDialog.changeFlag"
      />
    </v-sheet>
  </div>
</template>

<script>
import Loading from "@/components/loading";
import sideMenu from "@/components/Menu";
import SimpleDialog from "@/components/SimpleDialog";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { i18n } from "../../lang/lang.js";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import NavBar from "../../components/NavBar.vue";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import ConfirmDialog from "@/components/ConfirmDialog";
import CheckDialog from "@/components/ConfirmDialog";

export default {
  name: appConfig.SCREEN_ID.P_SRG_003,
  components: {
    Loading,
    sideMenu,
    SimpleDialog,
    ConfirmDialog,
    CheckDialog,
    NavBar,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  data: () => ({
    loadingCounter: 0,
    // 総件数
    totalCount: 0,
    // ページング
    itemsPerPageList: [10, 50, 100],
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    itemsPerPageBtn: "10",
    // 全選択チェックボックス
    isAllSelected: false,
    // 一覧選択チェックボックス
    listCheckbox: [1, 2],
    // 編集用チェックボックスリスト
    chengeListCheckbox: [],
    listCheckboxBtn: [],
    // 承認・未承認
    isConfirmed: "2",
    //検索ボタンをクリックした時に挙動を変える
    isActive: 1,
    // 承認ボタン活性化
    ableConfirm: true,
    // データ作成ボタン活性化
    confirmDone: true,
    // ダウンロードボタン活性化
    createDone: true,
    dialog: false,
    // 取引先
    suppliersSelected: "",
    suppliersNm: "",
    searchSupplierNm: "",
    // メニュー
    openMenu: null,
    // 指示日From
    instructionDateFromCal: "",
    instructionDateFrom: "",
    dateMenuFrom: false,
    // 指示日To
    instructionDateToCal: "",
    instructionDateTo: "",
    dateMenuTo: false,
    // カレンダー
    dateMenu: false,
    dateMenu2: false,
    returnURL: "",
    // 指示No
    txt_instructionNo: "",
    instructionNo: "",
    // 品番
    itemCdSelected: "",
    // 品番検索用
    search: "",
    // 指示内容
    instructionContentSelected: "",
    instructionContentNm: "",
    // 作業者
    workerSelected: "",
    // エラーメッセージ
    alertMessage: "",
    alertInstructionNoMessage: "",
    alertItemCdMessage: "",
    alertInstructionContentMessage: "",
    alertWorkerMessage: "",
    alertSupplierMessage: "",
    // ヘッダ
    supplierList: [],
    instructionContentList: [],
    workerList: [],
    instructionNoList: [],
    itemCdList: [],
    // 一覧
    inputList: [],
    //ページに表示する一覧
    showItemsPerPage: [],
    // 合計計算用リスト
    calcTotalList: [],
    // Apiから取得したリスト
    receivedApiList: [],
    detailList: [],
    // ソート
    sortOptions: {},
    // 検索時条件保持用
    // 取引先
    searchSuppliersSelected: "",
    // 指示No
    searchTxt_instructionNo: "",
    // 指示日From
    searchInstructionDateFrom: "",
    // 指示日To
    searchInstructionDateTo: "",
    // 品番
    searchItemCdSelected: "",
    // 品番品名
    searchItemCdList: [],
    // 指示内容
    searchInstructionContentSelected: "",
    // 作業者
    searchWorkerSelected: "",
    // 廃棄未承認
    searchUnApproval: "",
    // 処理区分
    completeFlg: "",
    // 検索フラグ
    searchFlg: false,

    // 確認メッセージダイアログ
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
      thirdPageFlag: false,
      screenFlag: false,
      changeFlag: false,
    },
    ConfirmDialog: {
      message: "",
      redMessage: "",
      okAction: () => {},
      isOpen: false,
    },
    checkDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
      okAction: () => {},
    },
    // 戻るボタンダイアログ
    updateDialogMessage: false,
    // 戻るボタンダイアログメッセージ
    backMessage: "",
    // メニュー
    isOpenEditDetailDialog: false,
    // 選択行
    selectedIndex: 0,
    // 廃棄未承認
    unApproval: "",
    unApprovalList: [],
    // 遷移フラグ
    transitionFlg: false,
    // 初期データ
    defaultData: [],
    // ヘッダ
    headerItems: [
      // チェックボックス
      { text: "選択", value: "check", width: "3%", align: "center", sortable: false },
      // 指示内容
      {
        text: i18n.tc("label.lbl_instructionContent"),
        value: "instructionContent",
        width: "7%",
        align: "left",
        sortable: true,
      },
      // 指示No
      {
        text: i18n.tc("label.lbl_instructionNo"),
        value: "instructionNo",
        width: "7%",
        align: "left",
        sortable: true,
      },
      // 指示日
      {
        text: i18n.tc("label.lbl_instructionDate"),
        value: "instructionDate",
        width: "7%",
        align: "left",
        sortable: true,
      },
      // 品番/品名
      {
        text: i18n.tc("label.lbl_productCnCd"),
        value: "itemCdName",
        width: "10%",
        align: "left",
        sortable: true,
      },
      // 変更前数量
      {
        text: i18n.tc("label.lbl_quantityBefore"),
        value: "totalQuantityBefore",
        width: "5%",
        align: "right",
        sortable: true,
      },
      // 変更後数量
      {
        text: i18n.tc("label.lbl_quantityAfter"),
        value: "totalQuantity",
        width: "5%",
        align: "right",
        sortable: true,
      },
      // 状況
      {
        text: i18n.tc("label.lbl_Situatuion"),
        value: "taglabel",
        width: "7%",
        align: "center",
        sortable: true,
      },
      // 詳細
      {
        text: i18n.tc("label.lbl_detail"),
        value: "detail",
        width: "7%",
        align: "center",
        sortable: false,
      },
    ],
    // バリデーション
    rules: {
      yyyymmdd: (value) =>
        (dateTimeHelper.validDate(commonUtil.zen2han(value), "/") &&
          dateTimeHelper.isDate(value)) ||
        i18n.tc("check.chk_inputDate"),
      supplierRequired: (value) => !!value || i18n.tc("check.chk_inputSupplier"),
      txt_instructionNoRequired: (value) => !!value || i18n.tc("check.chk_inputInstructionContent"),
    },
  }),
  methods: {
    // テーブルの余白調整
    u_10_check() {
      if (this.itemsPerPage < 11) {
        this.u_10 = true;
      }
    },
    u_10_on() {
      this.u_10 = true;
      this.itemsPerPage = 10;
      this.page = 1;
      this.searchApi();
    },
    u_10_clear(val) {
      this.u_10 = false;
      this.itemsPerPage = val;
      this.page = 1;
      this.searchApi();
    },
    // 初期化
    init() {
      // 初期データ保持
      this.defaultData = Object.assign({}, this.$data);
      // 初期表示
      this.itemCdSelected = "";
      this.suppliersSelected = "";
      this.workerSelected = "";
      this.inputList = [];
      this.instructionDateFrom = dateTimeHelper.convertJST();
      this.instructionDateFromCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
      this.instructionDateTo = dateTimeHelper.convertJST();
      this.instructionDateToCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
      this.getInitData();
      this.getCustomInfo();
      this.getUnApproval();
      //更新画面から遷移してきた時
      if (this.$route.params.backPage == "StockUpdateInstruction" && !this.clearFlg) {
        this.suppliersSelected = this.$route.params.detailSearchParam.searchSuppliersSelected;
        this.txt_instructionNo = this.$route.params.detailSearchParam.searchTxt_instructionNo;
        this.suppliersNm = this.$route.params.detailSearchParam.suppliersNm;
        this.instructionContentSelected =
          this.$route.params.detailSearchParam.searchInstructionContentSelected;
        this.txt_instructionNo = this.$route.params.detailSearchParam.searchTxt_instructionNo;
        this.instructionDateFrom = this.$route.params.detailSearchParam.searchInstructionDateFrom;
        this.instructionDateFromCal = dateTimeHelper.dateCalc(
          this.$route.params.detailSearchParam.searchInstructionDateFrom
        );
        this.instructionDateTo = this.$route.params.detailSearchParam.searchInstructionDateTo;
        this.instructionDateToCal = dateTimeHelper.dateCalc(
          this.$route.params.detailSearchParam.searchInstructionDateTo
        );
        this.itemCdSelected = this.$route.params.detailSearchParam.searchItemCdSelected;
        this.itemCdList = this.$route.params.detailSearchParam.searchItemCdList;
        this.searchItemCdList = this.itemCdList;
        this.workerSelected = this.$route.params.detailSearchParam.searchWorkerSelected;
        this.unApproval = this.$route.params.detailSearchParam.searchUnApproval;
        this.searchApi(true);
        this.transitionFlg = true;
        this.checkFlg = true;
      }
      //登録画面から遷移してきた時
      if (this.$route.params.backPage == "StockUpdateInstructionAdd" && !this.clearFlg) {
        this.suppliersSelected = this.$route.params.returnParam.searchSuppliersSelected;
        this.instructionContentSelected =
          this.$route.params.returnParam.searchInstructionContentSelected;
        this.suppliersNm = this.$route.params.returnParam.searchSuppliersNm;
        this.instructionContentNm = this.$route.params.returnParam.searchInstructionContent;
        this.txt_instructionNo = this.$route.params.returnParam.searchTxt_instructionNo;
        this.instructionDateFrom = this.$route.params.returnParam.searchInstructionDateFrom;
        this.instructionDateTo = this.$route.params.returnParam.searchInstructionDateTo;
        this.instructionDateFromCal = dateTimeHelper.dateCalc(
          this.$route.params.returnParam.searchInstructionDateFrom
        );
        this.instructionDateToCal = dateTimeHelper.dateCalc(
          this.$route.params.returnParam.searchInstructionDateTo
        );
        this.itemCdSelected = this.$route.params.returnParam.searchItemCdSelected;
        this.itemCdList = this.$route.params.returnParam.searchItemCdList;
        this.searchItemCdList = this.itemCdList;
        this.workerSelected = this.$route.params.returnParam.searchWorkerSelected;
        this.unApproval = this.$route.params.returnParam.searchUnApproval;
        this.transitionFlg = true;
        this.checkFlg = true;
        if (!this.$route.params.returnParam.searchFlg) {
          // 処理なし
        } else {
          this.searchApi(true);
        }
      }
    },
    // 初期データ取得
    getInitData() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 指示内容
      const instructionList = getParameter.getCodeMst(appConfig.CODETYPE.INSTRUCTION_CONTENT);
      Promise.all([instructionList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.instructionContentList = result[0];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    /**
     * 廃棄未承認取得
     */
    getUnApproval() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const approvalList = getParameter.getCodeMst(appConfig.CODETYPE.UN_APPROVAL);
      Promise.all([approvalList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.unApprovalList = result[0];
          if (!["01", "05"].includes(this.instructionContentSelected)) {
            this.unApprovalList = [];
          } else {
            switch (this.instructionContentSelected) {
              case "01":
                // 廃棄未承認
                this.unApprovalList = this.unApprovalList.filter((a) => {
                  return a.value === "01";
                });
                break;
              case "05":
                // 在庫調整未承認
                this.unApprovalList = this.unApprovalList.filter((a) => {
                  return a.value === "02";
                });
                break;
            }
          }
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    /**
     * 警告ダイアログ、OKボタン押下
     */
    updateOk() {
      this.updateDialogMessage = false;
    },
    /**
     * 初期データ取得
     */
    getCustomInfo() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // 取引先
      const clientList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertUTC()
      );
      // 作業者
      const userBizList = getParameter.getUserBiz();
      Promise.all([clientList, userBizList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.supplierList = result[0];
          this.workerList = result[1];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    /**
     * 指示日付Fromを－１日します。
     */
    prevDate() {
      if (this.instructionDateFromCal == null) {
        return;
      }
      let date = new Date(this.instructionDateFromCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.instructionDateFromCal != toDate) {
        this.instructionDateFromCal = toDate;
      } else {
        this.instructionDateFromCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
      }
    },

    /**
     * 指示日付Fromを＋１日します。
     */
    nextDate() {
      if (this.instructionDateFromCal == null) {
        return;
      }
      let date = new Date(this.instructionDateFromCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.instructionDateFromCal != toDate) {
        this.instructionDateFromCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 2));
      } else {
        this.instructionDateFromCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
      }
    },

    /**
     * 指示日付Fromを－１日します。
     */
    prevToDate() {
      if (this.instructionDateToCal == null) {
        return;
      }
      let date = new Date(this.instructionDateToCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.instructionDateToCal != toDate) {
        this.instructionDateToCal = toDate;
      } else {
        this.instructionDateToCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
      }
    },

    /**
     * 指示日付Fromを＋１日します。
     */
    nextToDate() {
      if (this.instructionDateToCal == null) {
        return;
      }
      let date = new Date(this.instructionDateToCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.instructionDateToCal != toDate) {
        this.instructionDateToCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 2));
      } else {
        this.instructionDateToCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
      }
    },

    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },

    /**
     * 日付入力(From)
     */
    changeDateFrom(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.instructionDateFromCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.instructionDateFromCal = null;
      }
    },

    /**
     * 日付入力(To)
     */
    changeDateTo(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.instructionDateToCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.instructionDateToCal = null;
      }
    },

    /**
     * URL発行
     */
    link() {
      location.href = this.returnURL;
    },

    /**
     * 取引先コードリストボックスセット処理
     */
    setSrhSupplier() {
      for (var i = 0; i < this.supplierList.length; i++) {
        if (this.supplierList[i].value == this.suppliersSelected) {
          return this.supplierList[i].name;
        }
      }
    },

    /**
     * 品番リストセット処理
     */
    setItemCd() {
      for (var i = 0; i < this.itemCdList.length; i++) {
        if (this.itemCdList[i].value == this.itemCdSelected) {
          return this.itemCdList[i].name;
        }
      }
    },
    /**
     * 廃棄未承認
     */
    setUnApproval() {
      for (var i = 0; i < this.unApprovalList.length; i++) {
        if (this.unApprovalList[i].value == this.unApproval) {
          return this.unApprovalList[i].name;
        }
      }
    },

    /**
     * 指示内容リストセット処理
     */
    setInstructionContent() {
      for (var i = 0; i < this.instructionContentList.length; i++) {
        if (this.instructionContentList[i].value == this.instructionContentSelected) {
          return this.instructionContentList[i].name;
        }
      }
    },
    /**
     * 作業者リストセット処理
     */
    setWorker() {
      for (var i = 0; i < this.workerList.length; i++) {
        if (this.workerList[i].value == this.workerSelected) {
          return this.workerList[i].name;
        }
      }
    },
    /**
     * 作業者取得
     */
    getWorker() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const config = this.$httpClient.createGetApiRequestConfig();
      config.params.isMst = "0";
      config.params.searchCategory = "0";
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.MST_WORKER, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // console.debug("getWorker() Response", response);
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              const list = [];
              jsonData.resIdv.worker.forEach((row) => {
                list.push({
                  text: row.workerLanguage[0].workerName,
                  name: row.workerLanguage[0].workerName,
                  value: row.workerLanguage[0].workerSid,
                });
                this.workerMap[row.workerLanguage[0].workerSid] = row.workerLanguage[0].workerName;
              });
              this.workerList = list;

              // エラー時
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;
            }
            resolve(response);
          })
          .catch((resolve) => {
            console.error("getInstructionContent() Resolve", resolve);
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;

            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * プルダウンチェンジイベント
     */
    changeProductNm(value) {
      // 既存品番/品名を選択時
      let val = this.itemCdList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.productHint = val.text;
        this.search = val.text;
      }
      this.itemCdList = this.itemCdList.filter((v) => v.value == value);
      this.searchItemCdList = this.itemCdList;
    },
    /**
     * 在庫情報変更指示一覧画面： 検索ボタン押下処理
     */
    searchApi() {
      this.isAllSelected = false;
      this.listCheckboxBtn = [];
      // this.getTextName();
      const config = this.$httpClient.createGetApiRequestConfig();

      // メッセージ初期化
      this.alertMessage = "";
      this.alertSupplierMessage = "";
      this.alertInstructionContentMessage = "";
      // 取引先存在チェック
      if (
        (this.suppliersSelected == "" || this.suppliersSelected == null) &&
        (this.instructionContentSelected == "" || this.instructionContentSelected == null)
      ) {
        this.alertSupplierMessage = i18n.tc("check.chk_inputSupplier");
        this.alertInstructionContentMessage = i18n.tc("check.chk_inputInstructionContent");
        return;
      }
      if (
        // FromToの日付、入力チェック
        new Date(this.instructionDateFrom).getTime() > new Date(this.instructionDateTo).getTime()
      ) {
        this.alertMessage = i18n.tc("check.chk_inputFromVali");
        return;
      } else {
        // 入力チェック結果フラグ
        let inputCheckResult = false;

        /**
         * 登録画面からの遷移の場合、入力チェックを実施するとvalidateが定義されていないと
         * エラーがでるので回避するため、このようなコードとなっている。
         */
        if (this.$route.params.backPage == "StockUpdateInstruction") {
          // 更新画面からの遷移の場合、入力チェックは実施しない。
          inputCheckResult = true;
        } else if (this.$route.params.backPage == "StockUpdateInstructionAdd") {
          // 登録画面からの遷移の場合、入力チェックは実施しない。
          inputCheckResult = true;
        } else {
          // 上記外の場合はチェックを行う。
          inputCheckResult = this.$refs.form.validate();
        }
        // 入力チェックを実施
        if (inputCheckResult) {
          this.loadingCounter = 1;

          // 取引先
          if (this.suppliersSelected) {
            config.params.clientSid = this.suppliersSelected;
          }
          // 品番標準SID
          if (this.itemCdSelected) {
            config.params.itemStandardSid = this.itemCdSelected;
          }
          // 指示No
          if (this.txt_instructionNo) {
            config.params.stockStateChangeNo = this.txt_instructionNo;
          }
          // 指示日From
          config.params.instDateFrom = dateTimeHelper.convertUTC(this.instructionDateFrom);

          // 指示日To
          let dateToRange = this.instructionDateTo + " 23:59:59.999";
          config.params.instDateTo = dateTimeHelper.convertUTC(dateToRange);

          // 指示内容
          if (this.instructionContentSelected) {
            config.params.stockStateChangeDiv = this.instructionContentSelected;
          }
          // 作業者
          if (this.workerSelected) {
            config.params.userSid = this.workerSelected;
          }
          // 廃棄未承認
          if (this.unApproval) {
            config.params.unApproval = this.unApproval;
          }

          // 検索時条件保持
          this.searchItemCdSelected = this.itemCdSelected;
          this.searchProdNameList =
            this.itemCdSelected == "" || this.itemCdSelected == null
              ? []
              : new Array(...this.itemCdList);
          this.searchInstructionDateFrom = this.instructionDateFrom;
          this.searchInstructionDateTo = this.instructionDateTo;
          this.searchInstructionContentSelected = this.instructionContentSelected;
          this.searchWorkerSelected = this.workerSelected;
          this.searchTxt_instructionNo = this.txt_instructionNo;
          this.searchSuppliersSelected = this.suppliersSelected;
          this.searchFlg = true;
          this.searchUnApproval = this.unApproval;

          // console.debug("searchBtn() Config", config);

          return new Promise((resolve, reject) => {
            this.$httpClient
              .secureGet(appConfig.API_URL.BIZ_STOCK_UPDATE_INSTRUCTION_LIST, config)
              .then((response) => {
                // console.debug("searchBtn() Response", response);
                const jsonData = JSON.parse(JSON.stringify(response.data));

                // 正常時
                if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
                  // 返却するリスト
                  const list = [];
                  let instructStateFlg = "";
                  jsonData.resIdv.stockChangeOrders.forEach((row) => {
                    // 処理区分
                    if (row.completeFlg == "0") {
                      this.completeFlg = i18n.tc("label.lbl_unprocessed");
                    } else {
                      this.completeFlg = i18n.tc("label.lbl_processed");
                    }
                    // 指示内容
                    if (row.stockStateChangeDiv == "01") {
                      instructStateFlg = i18n.tc("label.lbl_statusChange");
                    } else if (row.stockStateChangeDiv == "02") {
                      instructStateFlg = i18n.tc("label.lbl_lotChange");
                    } else if (row.stockStateChangeDiv == "03") {
                      instructStateFlg = i18n.tc("label.lbl_nameChange");
                    } else if (row.stockStateChangeDiv == "03") {
                      instructStateFlg = i18n.tc("label.lbl_nameChange");
                    } else if (row.stockStateChangeDiv == "04") {
                      instructStateFlg = i18n.tc("label.lbl_itemChange");
                    } else {
                      instructStateFlg = i18n.tc("label.lbl_stockAdjustment");
                    }

                    list.push({
                      instructionNo: row.stockStateChangeNo, // 指示No
                      instructionDate: dateTimeHelper.convertUTC2JST(row.instDate).substr(0, 10), //指示日
                      instructionDateTime: dateTimeHelper.convertUTC2JST(row.instDate), // 指示日時分秒
                      itemName: row.itemNameBefore, //品名
                      instructionContent: instructStateFlg, // 指示内容
                      stockStateChangeDiv: row.stockStateChangeDiv, // 指示内容
                      stockStateChangeSid: row.stockStateChangeSid, //在庫状態変更区分
                      officeSid: row.officeSid, //営業所SID

                      clientSidBefore: row.clientSidBefore, //変更前取引先SID
                      clientSidAfter: row.clientSidAfter, //変更後取引先SID
                      itemCdBefore: row.itemCdBefore, //変更前品番
                      itemCdAfter: row.itemCdAfter, //変更後品番
                      itemNameBefore: row.itemNameBefore, //変更前品名
                      itemNameAfter: row.itemNameAfter, //変更後品名
                      itemStandardSidBefore: row.itemStandardSidBefore, //変更前品質標準SID
                      itemStandardSidAfter: row.itemStandardSidAfter, //変更後品質標準SID
                      locationNoBefore: row.locationNoBefore, //変更前ロケーションNo
                      locationNoAfter: row.locationNoAfter, //変更後ロケーションNo
                      locationSidBefore: row.locationSidBefore, //変更前ロケーションSID
                      locationSidAfter: row.locationSidAfter, //変更後ロケーションSID
                      lotNoBefore: row.lotNoBefore, //変更前ロット
                      lotNoAfter: row.lotNoAfter, //変更後ロット
                      qualityDivBefore: row.qualityDivBefore, //変更前品質区分
                      qualityDivAfter: row.qualityDivAfter, //変更後品質区分
                      qualityNameBefore: row.qualityNameBefore, // 変更前品質名
                      qualityNameAfter: row.qualityNameAfter, // 変更後品質名
                      stockBefore: Number(row.stockBefore), //変更前数量
                      stockAfter: commonUtil.formatToCurrency(Number(row.stockAfter)), //変更後数量
                      warehouseNameBefore: row.warehouseNameBefore, // 変更前倉庫名
                      warehouseNameAfter: row.warehouseNameAfter, // 変更後倉庫名
                      warehouseSidBefore: row.warehouseSidBefore, //変更前倉庫SID
                      warehouseSidAfter: row.warehouseSidAfter, //変更後倉庫SID

                      itemStandardSid: row.itemStandardSidBefore, //画面表示品質標準SID
                      itemCd: row.itemCdBefore.substring(0, 50).trim(), //画面表示品番
                      incidental: row.itemCdBefore.slice(-50).trim(), //付属品番

                      qualityDiv: row.qualityDivAfter, //品質区分
                      quantity: Number(row.stockAfter), //変更後数量
                      itemManageNo: row.itemManageNo, //管理No
                      changeReasonDiv: row.changeReasonDiv, //変更理由区分
                      freeCostDiv: row.freeCostDiv, //有償無償区分
                      freeCostReasonDiv: row.freeCostReasonDiv, //無償理由区分
                      userSid: row.userSid, //ユーザSID
                      remarks: row.remarks, //備考
                      taglabel: this.completeFlg, //状況
                      approvalFlg: row.approvalFlg, //承認フラグ
                      workflowMatterId: row.workflowMatterId, // ワークフロー案件ID
                      completeFlg: row.completeFlg, //完了フラグ
                      deleteFlg: row.deleteFlg, //削除フラグ
                      deviceId: row.deviceId, //端末識別番号
                      appVersion: row.appVersion, //アプリバージョン
                      createUserCd: row.createUserCd, //作成者
                      updateUserCd: row.updateUserCd, //更新者
                      updateDatetime: row.updateDatetime, //更新日時
                      stockCheckSid: row.stockCheckSid, // 在庫状態変更指示SID
                      locationNo: row.locationNo, // ロケーションNo
                      qualityName: row.qualityNameAfter, // 品質名
                      warehouseName: row.warehouseNameAfter, // 倉庫名
                      yyyymmddHhmmss: row.yyyymmddHhmmss, // 日付
                      beforeQualityDiv: row.beforeQualityDiv, // 変更前ステータス
                      check: false,
                    });
                  });
                  this.receivedApiList = list;
                  this.detailList = list;

                  this.showList();
                  if (this.unApproval == "01") {
                    this.selectAllCheck();
                  }
                } else {
                  // エラーメッセージをpopupのmessageに格納
                  this.infoDialog.message = jsonData.resCom.resComMessage;
                  this.infoDialog.title = appConfig.DIALOG.title;
                  this.infoDialog.isOpen = true;
                  this.infoDialog.firstPageFlag = true;
                }
                resolve();
              })
              .catch((ex) => {
                this.infoDialog.message = ex;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = true;
                reject();
              })
              .finally(() => {
                // ローディング画面表示OFF
                this.loadingCounter = 0;
              });
          });
        } else {
          // 入力チェックエラーの場合
          this.isInputCheck = false;
        }
      }
    },
    /**
     * TODO: 一覧画面表示用リスト作成
     */
    showList() {
      // 画面表示リスト初期化
      this.inputList = [];

      // 指示No順になっているリストから重複指示Noを除く
      // 最初一番上のデータをinputListに挿入
      if (this.receivedApiList.length > 0) {
        this.inputList.push(this.receivedApiList[0]);
      }
      // 比較して指示Noが違う一番上のデータをinputListに挿入
      for (var k = 0; k < this.receivedApiList.length - 1; k++) {
        if (this.receivedApiList[k].instructionNo != this.receivedApiList[k + 1].instructionNo) {
          this.inputList.push(this.receivedApiList[k + 1]);
        }
      }

      //数量合計
      for (var i = 0; i < this.inputList.length; i++) {
        let sumQuantity = 0;
        let sumQuantityBefore = 0;
        for (var j = 0; j < this.receivedApiList.length; j++) {
          if (this.inputList[i].instructionNo === this.receivedApiList[j].instructionNo) {
            sumQuantityBefore =
              Number(sumQuantityBefore) + Number(this.receivedApiList[j].stockBefore);
            sumQuantity = Number(sumQuantity) + Number(this.receivedApiList[j].quantity);
          }
        }
        this.inputList[i].totalQuantity = sumQuantity;
        this.inputList[i].totalQuantityBefore = sumQuantityBefore;
      }

      // 画面表示用総件数
      this.totalCount = this.inputList.length;

      // ソート
      if (this.sortItem != "") {
        this.inputList = this.sortChange(this.inputList, this.ascdesc, this.sortBy);
      }

      // ページング処理
      this.showItemsPerPage = this.inputList;
      this.inputList = [];
      // 該当ページ表示件数設定
      if (this.itemsPerPage == "100") {
        this.pageCount = Math.ceil(this.totalCount / 100);
      } else if (this.itemsPerPage == "50") {
        this.pageCount = Math.ceil(this.totalCount / 50);
      } else {
        this.pageCount = Math.ceil(this.totalCount / 10);
      }
      // 現ページ最初行、最後行取得
      const first = (this.page - 1) * this.itemsPerPage + 1;
      const last = this.page * this.itemsPerPage;
      // 現ページ全行取得設定
      this.showItemsPerPage.forEach((item, i) => {
        if (i < first - 1 || i > last - 1) return;
        this.inputList.push(item);
      });
    },

    /**
     * 在庫情報変更指示一覧画面： データ出力ボタン押下処理
     */
    download() {
      this.isAllSelected = false;
      this.listCheckboxBtn = [];
      // this.getTextName();
      const body = this.$httpClient.createRequestBodyConfig();

      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_STC_001; // 画面ID

      // メッセージ初期化
      this.alertMessage = "";
      this.alertSupplierMessage = "";
      this.alertInstructionContentMessage = "";
      // 取引先存在チェック
      if (
        (this.suppliersSelected == "" || this.suppliersSelected == null) &&
        (this.instructionContentSelected == "" || this.instructionContentSelected == null)
      ) {
        this.alertSupplierMessage = i18n.tc("check.chk_inputSupplier");
        this.alertInstructionContentMessage = i18n.tc("check.chk_inputInstructionContent");
        return;
      }
      if (
        // FromToの日付、入力チェック
        new Date(this.instructionDateFrom).getTime() > new Date(this.instructionDateTo).getTime()
      ) {
        this.alertMessage = i18n.tc("check.chk_inputFromVali");
        return;
      } else {
        // 入力チェック結果フラグ
        let inputCheckResult = false;

        /**
         * 登録画面からの遷移の場合、入力チェックを実施するとvalidateが定義されていないと
         * エラーがでるので回避するため、このようなコードとなっている。
         */
        if (this.$route.params.backPage == "StockUpdateInstruction") {
          // 更新画面からの遷移の場合、入力チェックは実施しない。
          inputCheckResult = true;
        } else if (this.$route.params.backPage == "StockUpdateInstructionAdd") {
          // 登録画面からの遷移の場合、入力チェックは実施しない。
          inputCheckResult = true;
        } else {
          // 上記外の場合はチェックを行う。
          inputCheckResult = this.$refs.form.validate();
        }
        // 入力チェックを実施
        if (inputCheckResult) {
          this.loadingCounter = 1;

          // 取引先
          if (this.suppliersSelected) {
            body.reqIdv.clientSid = this.suppliersSelected;
          }
          // 品番標準SID
          if (this.itemCdSelected) {
            body.reqIdv.itemStandardSid = this.itemCdSelected;
          }
          // 指示No
          if (this.txt_instructionNo) {
            body.reqIdv.stockStateChangeNo = this.txt_instructionNo;
          }
          // 指示日From
          body.reqIdv.instDateFrom = dateTimeHelper.convertUTC(this.instructionDateFrom);

          // 指示日To
          let dateToRange = this.instructionDateTo + " 23:59:59.999";
          body.reqIdv.instDateTo = dateTimeHelper.convertUTC(dateToRange);

          // 指示内容
          if (this.instructionContentSelected) {
            body.reqIdv.stockStateChangeDiv = this.instructionContentSelected;
          }
          // 作業者
          if (this.workerSelected) {
            body.reqIdv.userSid = this.workerSelected;
          }
          // 廃棄未承認
          if (this.unApproval) {
            body.reqIdv.unApproval = this.unApproval;
          }

          // console.debug("searchBtn() Config", config);

          return new Promise((resolve, reject) => {
            this.$httpClient
              .securePost(appConfig.API_URL.BIZ_STOCK_UPDATE_INSTRUCTION_LIST_FILE_DOWNLOAD, body)
              .then((response) => {
                // console.debug("searchBtn() Response", response);
                const jsonData = JSON.parse(JSON.stringify(response.data));
                // 正常時
                if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
                  location.href = jsonData.resIdv.downloadUrl;
                } else {
                  // エラーメッセージをpopupのmessageに格納
                  this.infoDialog.message = jsonData.resCom.resComMessage;
                  this.infoDialog.title = appConfig.DIALOG.title;
                  this.infoDialog.isOpen = true;
                  this.infoDialog.firstPageFlag = true;
                }
                resolve();
              })
              .catch((ex) => {
                this.infoDialog.message = ex;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = true;
                reject();
              })
              .finally(() => {
                // ローディング画面表示OFF
                this.loadingCounter = 0;
              });
          });
        } else {
          // 入力チェックエラーの場合
          this.isInputCheck = false;
        }
      }
    },

    /**
     * TODO:リストソート
     */
    sortChange: function (array, order) {
      if (!order || !order.match(/^(ASC|DESC)$/i)) order = "ASC";
      order = order.toUpperCase();

      var keys = [];
      for (var i = 2, len = arguments.length; i < len; i++) keys.push(arguments[i]);

      var targets = [].concat(array);

      targets.sort(function (a, b) {
        for (var i = 0, len = keys.length; i < len; i++) {
          if (typeof keys[i] === "string") {
            if (order === "ASC") {
              if (a[keys[i]] < b[keys[i]]) return -1;
              if (a[keys[i]] > b[keys[i]]) return 1;
            } else {
              if (a[keys[i]] > b[keys[i]]) return -1;
              if (a[keys[i]] < b[keys[i]]) return 1;
            }
          } else {
            var localOrder = keys[i].order || "ASC";
            if (!localOrder.match(/^(ASC|DESC)$/i)) order = "ASC";
            order = order.toUpperCase();

            if (localOrder === "ASC") {
              if (a[keys[i].key] < b[keys[i].key]) return -1;
              if (a[keys[i].key] > b[keys[i].key]) return 1;
            } else {
              if (a[keys[i].key] > b[keys[i].key]) return -1;
              if (a[keys[i].key] < b[keys[i].key]) return 1;
            }
          }
        }

        return 0;
      });

      // this.sortItem = "";
      return targets;
    },
    /**
     * TODO: 詳細ボタン押下処理
     */
    editdetail(item) {
      this.getTextName();

      // 詳細ボタン押下時伝送するリスト取得
      const allDataList = this.detailList;
      const filteredList = allDataList.filter((x) => x.instructionNo === item.instructionNo);
      this.$router.push({
        name: appConfig.SCREEN_ID.P_STC_002,
        params: {
          detailSearchParam: {
            // TODO
            instructionFlg: item.stockStateChangeDiv,
            // 取引先Name
            suppliersSelected: this.suppliersSelected,
            supplierNm: this.suppliersNm,
            // 指示内容
            instructionContentNm: item.instructionContent,
            // 指示No
            instructionNo: item.instructionNo,
            // 指示日From
            instructionDate: item.instructionDate,
            // 指示日TO
            instructionDateTo: this.instructionDateTo,
            // 品番
            itemCdSelected: this.itemCdSelected,
            // 作業者
            workerSelected: this.workerSelected,
            // 変更理由区分
            changeReasonDiv: item.changeReasonDiv,
            // ステータス
            qualityDivAfter: item.qualityDivAfter,
            // ロット
            lotNoAfter: item.lotNoAfter,
            // 変更前名義(取引先)
            clientSidBefore: item.clientSidBefore,
            // 変更後名義(取引先)
            changeSupplier: item.clientSidAfter,
            // 変更後品番
            changeItemSid: item.itemStandardSidAfter,
            changeItemCd: item.itemCdAfter,
            changeItemName: item.itemNameAfter,
            // 有償無償区分
            freeCostDiv: item.freeCostDiv,
            // 無償理由
            freeCostReasonDiv: item.freeCostReasonDiv,
            // 作業者
            userSid: item.userSid,
            // 備考欄
            remarks: item.remarks,
            // 指示日時分秒
            instructionDateTime: item.instructionDateTime,
            // 伝送する検索結果
            filteredList,

            //検索時条件保持用
            //取引先
            searchSuppliersSelected: this.searchSuppliersSelected,
            //指示No
            searchTxt_instructionNo: this.searchTxt_instructionNo,
            //指示日From
            searchInstructionDateFrom: this.searchInstructionDateFrom,
            //指示日To
            searchInstructionDateTo: this.searchInstructionDateTo,
            //品番
            searchItemCdSelected: this.searchItemCdSelected,
            //品番品名
            searchItemCdList: this.searchProdNameList,
            //指示内容
            searchInstructionContentSelected: this.searchInstructionContentSelected,
            //作業者
            searchWorkerSelected: this.searchWorkerSelected,
            //取引先名
            searchSupplierNm: this.searchSupplierNm,
            // 廃棄未承認
            searchUnApproval: this.searchUnApproval,
          },
        },
      });
    },
    // 詳細ボタン押下時伝送するリスト取得
    getFilteredList(item) {
      const allDataList = this.receivedApiList;
      const result = allDataList.filter((x) => x.instructionNo === item.instructionNo);
      this.filteredList = result;
    },
    // 次画面表示用の名称取得
    getTextName() {
      // 取引先名と取引先コードを取得
      for (var k = 0; k < this.supplierList.length; k++) {
        if (this.supplierList[k].value == this.suppliersSelected) {
          this.suppliersNm = this.supplierList[k].text;
        }
      }
      for (var l = 0; l < this.supplierList.length; l++) {
        if (this.supplierList[l].value == this.searchSuppliersSelected) {
          this.searchSupplierNm = this.supplierList[l].text;
        }
      }
      for (var i = 0; i < this.instructionContentList.length; i++) {
        if (this.instructionContentList[i].value == this.instructionContentSelected) {
          this.instructionContentNm = this.instructionContentList[i].text;
        }
      }
    },
    // 新規登録ボタン押下
    goInitRegist() {
      if (!this.searchFlg) {
        // 在庫情報変更指示登録画面（新規登録）へ遷移
        this.$router.push({
          name: appConfig.SCREEN_ID.P_STC_003,
          params: {
            addSearchParam: {
              //取引先
              searchSuppliersSelected: this.suppliersSelected,
              //指示No
              searchTxt_instructionNo: this.txt_instructionNo,
              //指示日From
              searchInstructionDateFrom: this.instructionDateFrom,
              //指示日To
              searchInstructionDateTo: this.instructionDateTo,
              //品番
              searchItemCdSelected: this.itemCdSelected,
              //品番品名
              searchItemCdList: this.itemCdList,
              //指示内容
              searchInstructionContentSelected: this.instructionContentSelected,
              //作業者
              searchWorkerSelected: this.workerSelected,
              //検索グラグ
              searchFlg: this.searchFlg,
              // 廃棄未承認
              searchUnApproval: this.unApproval,
            },
          },
        });
      } else {
        // 在庫情報変更指示登録画面（新規登録）へ遷移
        this.$router.push({
          name: appConfig.SCREEN_ID.P_STC_003,
          params: {
            addSearchParam: {
              //取引先
              searchSuppliersSelected: this.searchSuppliersSelected,
              //指示No
              searchTxt_instructionNo: this.searchTxt_instructionNo,
              //指示日From
              searchInstructionDateFrom: this.searchInstructionDateFrom,
              //指示日To
              searchInstructionDateTo: this.searchInstructionDateTo,
              //品番
              searchItemCdSelected: this.searchItemCdSelected,
              //品番品名
              searchItemCdList: this.searchProdNameList,
              //指示内容
              searchInstructionContentSelected: this.searchInstructionContentSelected,
              //作業者
              searchWorkerSelected: this.searchWorkerSelected,
              //検索グラグ
              searchFlg: this.searchFlg,
              // 廃棄未承認
              searchUnApproval: this.searchUnApproval,
            },
          },
        });
      }
    },
    /**
     * 全選択チェックボックス処理
     */
    selectAllCheck() {
      if (!this.isAllSelected) {
        this.listCheckbox = [];
        this.listCheckbox = true;
        this.isAllSelected = true;
        for (var i = 0; i < this.inputList.length; i++) {
          this.inputList[i].check = true;
          this.listCheckboxBtn.push(this.inputList[i]);
        }
      } else {
        this.listCheckbox = [];
        this.listCheckbox = false;
        for (var j = 0; j < this.inputList.length; j++) {
          this.inputList[j].check = false;

          // 画面遷移用リストを初期化
          this.listCheckboxBtn = [];
        }
        this.isAllSelected = false;
      }
    },
    /**
     * チェックボックス押下処理
     */
    checkList(item, index) {
      if (!item.check) {
        // チェックを入れた場合
        this.inputList[index].check = true;

        // 編集用リスト
        this.chengeListCheckbox.push(item);

        // 画面遷移用リスト
        this.listCheckboxBtn.push(item);
      } else {
        // チェックを外した場合
        this.inputList[index].check = false;

        // 画面遷移用リストを初期化
        this.listCheckboxBtn = [];

        // チェックを外したレコード以外を画面遷移用リストに詰めなおす
        for (var i = 0; i < this.inputList.length; i++) {
          if (this.inputList[i].check != false) {
            this.listCheckboxBtn.push(this.inputList[i]);
          }
        }

        // 編集用リストも更新
        this.chengeListCheckbox = [];
        for (var j = 0; j < this.listCheckboxBtn.length; j++) {
          this.chengeListCheckbox.push(this.listCheckboxBtn[j]);
        }
        if (this.listCheckboxBtn.length < this.inputList.length) {
          this.isAllSelected = false;
        }

        if (this.listCheckboxBtn.length == 0 && this.chengeListCheckbox.length == 0) {
          this.isAllSelected = false;
        }
      }
    },
    // 承認しますかのアラート
    approvalApi() {
      if (this.listCheckboxBtn.length == 0 || this.listCheckboxBtn.length < 0) {
        this.updateDialogMessage = true;
        this.backMessage = messsageUtil.getMessage("P-EXT-003_001_E");
        return;
      }
      let cnt = 0;
      for (var i = 0; i < this.inputList.length; i++) {
        if (this.inputList[i].check) {
          if (
            ((this.inputList[i].stockStateChangeDiv != "01" ||
              this.inputList[i].qualityDivAfter != "07") &&
              this.inputList[i].stockStateChangeDiv != "05") ||
            this.inputList[i].approvalFlg != "0"
          ) {
            cnt = cnt + 1;
          }
        }
      }
      if (0 < cnt) {
        this.infoDialog.message = messsageUtil.getMessage("P-STC-001_006_E");
        this.infoDialog.title = appConfig.DIALOG.title;
        this.infoDialog.isOpen = true;
        this.infoDialog.firstPageFlag = true;
      } else {
        this.ConfirmDialog.message = messsageUtil.getMessage("P-CNT-006_001_E");
        this.ConfirmDialog.isOpen = true;
        this.ConfirmDialog.screenFlag = true;
        this.ConfirmDialog.changeFlag = false;
      }
    },
    // 承認処理
    postApproval() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const body = this.$httpClient.createRequestBodyConfig();
      //★必須パラメータ
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_CNT_006;

      var stockDefectApproval = [];

      for (var n = 0; n < this.inputList.length; n++) {
        if (this.inputList[n].check) {
          stockDefectApproval.push({
            stockStateChangeDiv: this.inputList[n].stockStateChangeDiv, //指示内容
            stockStateChangeSid: this.inputList[n].stockStateChangeSid, //在庫変更指示SID
            updateDatetime: this.inputList[n].updateDatetime, //更新日
          });
        } else {
          // 処理しない
        }
      }

      body.reqIdv.stockDefectApproval = stockDefectApproval.slice();

      // console.debug("postApi() Body", body);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.BIZ_STOCK_DEFECT_APPROVAL, body, appConfig.APP_CONFIG)
          .then((response) => {
            // console.debug("postApi() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              const reSearch = this.searchApi();
              Promise.all([reSearch])
                .then(() => {
                  this.infoDialog.message = messsageUtil.getMessage("P-CNT-006_002_C");
                  this.infoDialog.title = "結果";
                  this.infoDialog.isOpen = true;
                  this.infoDialog.screenFlag = true;
                  this.infoDialog.firstPageFlag = true;
                  resolve(response);
                })
                .catch((ex) => {
                  this.infoDialog.message = ex;
                  this.infoDialog.title = appConfig.DIALOG.title;
                  this.infoDialog.isOpen = true;
                  this.infoDialog.firstPageFlag = true;
                  reject();
                });
              // エラー時
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;

              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;

            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    /**
     * 画面クリアボタン押下時の処理
     */
    clearScreen() {
      this.checkDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_clear"),
      ]);
      this.checkDialog.isOpen = true;
      this.checkDialog.screenFlag = true;
      this.checkDialog.changeFlag = false;
      this.checkDialog.okAction = this.clearScreenOkClick;
    },
    /**
     * 画面クリア
     */
    clearScreenOkClick() {
      // 初期表示の設定
      Object.assign(this.$data, this.defaultData);
      // 画面遷移後の場合
      if (this.checkFlg) {
        // 遷移フラグ
        this.checkFlg = false;
        // クリアフラグ
        this.clearFlg = true;
      }
      // 変更検知用の設定
      this.instructionDateFromCal = "";
      this.instructionDateToCal = "";
      // 初期化
      this.init();
      // バリデーションチェックをリセット
      this.$refs.form.resetValidation();
    },
  },
  computed: {
    isCanNotDownloadFlg() {
      let isCanNotDownloadFlg = true;
      // 廃棄未承認ボタンが選択されている場合はダウンロードする対象のデータが存在しないため日活性にする
      if (!this.unApproval) {
        isCanNotDownloadFlg = false;
      }
      isCanNotDownloadFlg = this.inputList.length <= 0 || isCanNotDownloadFlg;
      return isCanNotDownloadFlg;
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    page: function (newValue) {
      this.page = newValue;
      // ソート押下後すでに検索した結果を元にしてページを表示する
      this.showList();
    },
    sortOptions: {
      handler(items) {
        // console.debug("watch sortByApi", items);
        if (items.sortBy.length < 1) {
          this.sortItem = "";
        } else {
          var sortBy = items.sortBy[0];
          //itemCdでソートするため
          sortBy = sortBy == "itemCdName" ? "itemCd" : sortBy;
          this.sortBy = sortBy;
          var ascdesc = items.sortDesc[0] ? " desc" : " asc";
          this.ascdesc = ascdesc.trim();
          this.ascdesc = this.ascdesc.toUpperCase();
          this.sortItem = appConfig.P_STC_001_SORT[sortBy] + ascdesc;
          this.showList();
        }
      },
      deep: true,
    },
    dialog(val) {
      val || this.close();
    },
    suppliersSelected() {
      if (this.transitionFlg) {
        // 遷移後
        return;
      } else {
        //品番/品名クリア
        this.itemCdSelected = "";
        this.itemCdList = [];
        this.alertSupplierMessage = "";
      }
    },
    itemCdSelected() {
      this.alertItemCdMessage = "";
    },
    instructionDateFromCal: {
      handler(val) {
        this.instructionDateFrom = this.formatDate(val);
        this.alertMessage = "";
      },
      deep: true,
    },
    instructionDateToCal: {
      handler(val) {
        this.instructionDateTo = this.formatDate(val);
        this.alertMessage = "";
      },
      deep: true,
    },
    instructionContentSelected() {
      this.alertInstructionContentMessage = "";
      this.getUnApproval();
    },
    search(val) {
      if (val ?? false) {
        // 取引先が選択されてない場合、処理しない
        if (this.suppliersSelected == null || this.suppliersSelected == "") {
          return;
        }
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.productHint) {
          return;
        }
        // 遷移後
        if (this.transitionFlg) {
          this.transitionFlg = false;
          return;
        }
        // 入力桁数が定義した数異常の場合検索処理
        if (val.length >= appConfig.CNTCHARITEM) {
          const itemsBiz = getParameter.getItemsBizAddName2(
            this.suppliersSelected,
            dateTimeHelper.convertUTC(),
            val
          );

          Promise.all([itemsBiz])
            .then((result) => {
              // 画面の初期値を設定します。
              const list = [];
              this.itemCdList = list.concat(result[0]);
              this.itemCdList.unshift({ name: "", text: "", value: "" });
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        } else {
          this.itemCdList.length = 0;
        }
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}

.toatlNum {
  margin-left: 10px;
}

.text-overflow {
  width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.txt_instructionNo {
  width: 275px;
}
</style>
