<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <!-- 入荷予定データ取込 -->
    <v-sheet class="overflow-hidden">
      <Loading />
      <NavBar tittle="label.lbl_receivingStockCompare" />
      <v-form ref="form" lazy-validation>
        <v-container fluid>
          <!-- 検索項目 -->
          <v-row class="search-row">
            <div class="search-autocomplete first-search-item" style="width: 450px">
              <!--  取引先 -->
              <v-autocomplete
                dense
                v-model="suppliersSelected"
                :items="supplierList"
                :label="$t('label.lbl_supplier')"
                class="txt-single"
                :hint="setSrhSupplier()"
                :rules="[rules.inputRequired]"
                :error-messages="alertSupplierMessage"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data" class="nal">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require">*</span>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 比較予定日付-->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="compareScheduleDate"
                :label="$t('label.lbl_compareScheduleDate')"
                @change="changeDate"
                class="txt-single"
                :rules="compareScheduleDate ? [rules.yyyymmdd] : []"
                clear-icon="mdi-close-circle"
                clearable
                :error-messages="alertDateMessage"
              ></v-text-field>
            </div>

            <!--カレンダーボタン領域-->
            <div class="calender-btn-area">
              <v-menu
                v-model="dateMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="compareScheduleDateCal"
                  @input="dateMenu = false"
                ></v-date-picker>
              </v-menu>
              <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="prevDate" text x-small>
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextDate" text x-small>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>

            <!-- 取込ファイル -->
            <div class="file-input">
              <v-file-input
                prepend-icon=""
                :label="$t('label.lbl_importFile')"
                outlined
                dense
                v-model="importfile"
                counter
                :rules="[rules.fileRequired]"
                append-outer-icon="mdi-paperclip"
                @click:append-outer="handleClickAppendOuter"
                ref="vFileInput"
                :error-messages="alertMessage"
              >
              </v-file-input>
            </div>
            <span class="require">*</span>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 結果表示区分-->
            <div class="search-autocomplete first-search-item" style="float: left">
              <v-autocomplete
                dense
                v-model="processDivName"
                :items="processDivList"
                :label="$t('label.lbl_confirmDisplay')"
                :hint="setStsArrival()"
                class="txt-single"
                :rules="[rules.inputRequired]"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require">*</span>
          </v-row>
          <v-row>
            <div class="search-row-exeBtn first-search-item">
              <!--検索結果件数表示-->
              <!-- <div class="text-label" style="float: left">
                <span class="toatlNum">総件数：{{ totalCount }}件</span>
              </div> -->
              <!-- 空白 -->
              <span class="item-spacer">&nbsp;</span>

              <div class="btn-search-area" style="float: left">
                <!-- 在庫差異確認ボタン -->
                <v-btn
                  color="primary"
                  id="get-search"
                  class="api-btn"
                  :disabled="compareBtnActive"
                  @click="search()"
                  >{{ $t("btn.btn_stockTransitionConfirm") }}</v-btn
                >
              </div>
              <div class="btn-area">
                <!-- CSV出力ボタン -->
                <v-btn
                  color="primary"
                  id="get-outPut"
                  :disabled="outputBtnActive"
                  class="api-btn"
                  @click="beforeCsv()"
                >
                  {{ $t("btn.btn_outputcsv") }}
                </v-btn>
              </div>
              <!--数量合計領域-->
              <!-- <div
                style="
                  float: left;
                  display: flex;
                  align-items: center;
                  width: 66%;
                  flex-direction: row-reverse;
                "
              > -->
              <!-- <div
                  style="
                    margin-left: 85%;
                    font-weight: bold;
                    font-size: large;
                    border-bottom: double;
                  "
                > -->
              <!-- 数量合計-->
              <!-- {{ $t("label.lbl_totalQty") + " " + sumCount.toLocaleString() }}
                </div> -->
              <!-- <div class="text-center">
                  <span class="toatlNum">数量合計：{{ sumCount }}件</span>
                </div> -->
              <!--ボタン領域-->
              <!-- <div class="text-label">
              </div> -->
              <!--表示件数切替部分-->
              <!-- <div style="float: right"> -->
              <!--表示件数-->
              <!-- <div class="text-label" style="clear: both; float: left">
                  {{ $t("label.lbl_itemsPerPage") }}
                </div> -->
              <!-- 空白 -->
              <!-- <span class="item-spacer" style="float: left">&nbsp;</span> -->
              <!--表示件数切替ボタン-->
              <!-- <div style="float: left">
                  <v-btn-toggle
                    v-model="itemsPerPageBtn"
                    color="blue darken-2"
                    class="toggle black--text"
                    right
                  >
                    <v-btn class="pasing-btn" value="10" @click="u_10_on()" small>
                      {{ 10 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="50" @click="u_10_clear(50)" small>
                      {{ 50 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="100" @click="u_10_clear(100)" small>
                      {{ 100 }}
                    </v-btn>
                  </v-btn-toggle>
                </div>-->
              <!-- </div> -->
            </div>
          </v-row>
        </v-container>
      </v-form>
      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="listData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          :page.sync="page"
          :server-items-length="itemsPerPage"
          disable-filtering
          :hide-default-footer="true"
          height="610px"
          :options.sync="sortOptions"
        >
          <template v-slot:[`item.product`]="{ item }">
            <!-- 品番/品名 -->
            <div>{{ item.itemCd }}</div>
            <div>{{ item.itemName }}</div>
          </template>
          <template v-slot:[`item.isInListNo`]="{ item }">
            <!-- 付帯品番 -->
            <!-- <div v-bind:class="{ isD: item.isInListNoFlg }"></div> -->
            <div>{{ item.isInListNo }}</div>
          </template>
          <template v-slot:[`item.inListNo`]="{ item }">
            <!-- ロット -->
            <!-- <div v-bind:class="{ isD: item.isInListNoFlg }"></div> -->
            <div>{{ item.inListNo }}</div>
          </template>
          <template v-slot:[`item.stock`]="{ item }">
            <!-- 在庫数 -->
            <div>
              {{ item.stock.toLocaleString() }}
            </div>
          </template>
          <template v-slot:[`item.consinorStock`]="{ item }">
            <!-- 荷主在庫数 -->
            <div>
              {{ item.consinorStock.toLocaleString() }}
            </div>
          </template>
          <template v-slot:[`item.diffStock`]="{ item }">
            <!-- 在庫差異 -->
            <div v-if="item.diffStock.substr(0, 1) == '-'" class="isR">
              {{ item.diffStock.toLocaleString() }}
            </div>
            <div v-else-if="item.diffStock.substr(0, 1) == '+'" class="isB">
              {{ item.diffStock.toLocaleString() }}
            </div>
            <div v-else class="isD">{{ item.diffStock.toLocaleString() }}</div>
          </template>
          <template v-slot:[`item.unitGr`]="{ item }">
            <!-- 単価Gr -->
            <div>{{ item.unitGr }}</div>
          </template>
        </v-data-table>
        <!-- 詳細画面ポップアップ -->
        <v-dialog v-model="dialogDetails" :max-width="2000" persistent no-click-animation>
          <ReceivingStockCompareCsvError
            :isOpenDetailDialog.sync="dialogDetails"
            :entity="detailDialogData"
            class="overflow"
            v-on:closePopup="closePopup"
          />
        </v-dialog>
        <!-- <div class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
        </div> -->
        <!-- チェックボックスチェック後ダイアログ -->
        <v-dialog v-model="fileCheckDialogMessage" :max-width="800">
          <v-card>
            <v-card-title class="blue-grey lighten-3" primary-title>
              {{ $t("btn.btn_ok") }}
            </v-card-title>
            <v-card-text class="pa-4">
              <p>{{ backMessage }}</p>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="close"> 閉じる </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :screenFlag="infoDialog.screenFlag"
        :homePageFlag="infoDialog.homePageFlag"
        :rcvSchListFlg="infoDialog.rcvSchListFlg"
        :rcvSchAddFlg="infoDialog.rcvSchAddFlg"
        :firstPageFlag="infoDialog.firstPageFlag"
      />

      <!-- メッセージダイアログ-->
      <ConfirmDialog
        :isShow.sync="ConfirmDialog.isOpen"
        :message="ConfirmDialog.message"
        :screenFlag="ConfirmDialog.screenFlag"
        :okAction="ConfirmDialog.okAction"
        :redMessage="ConfirmDialog.redMessage"
        :changeFlag="ConfirmDialog.changeFlag"
      />
    </v-sheet>
  </div>
</template>
<script>
import Loading from "@/components/loading";
import ConfirmDialog from "@/components/ConfirmDialog";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
//import { functionAuth } from "../assets/scripts/js/FunctionAuth";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import { i18n } from "../../lang/lang.js";
// import HeaderBar from "@/components/HeaderBar.vue";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import NavBar from "../../components/NavBar.vue";
import ReceivingStockCompareCsvError from "../stock/ReceivingStockCompareCsvError.vue";
import commonMixin from "@/mixins/CommonMixin";
import { commonFunction } from "../../assets/scripts/js/CommonFunction";

export default {
  name: appConfig.SCREEN_ID.P_SHP_002,
  components: {
    Loading,
    SimpleDialog,
    ConfirmDialog,
    sideMenu,
    NavBar,
    ReceivingStockCompareCsvError,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  mixins: [commonMixin],
  data: () => ({
    loadingCounter: 0,
    sortItem: "",
    sortOptions: {},
    importfile: {},
    // 比較予定日
    compareScheduleDate: "",
    compareScheduleDateCal: "",
    // カレンダー
    dateMenu: false,
    // ファイル名
    fileName: {},
    // S3ファイル名
    s3FileName: {},
    // 取引先
    suppliersSelected: "",
    supplierList: [],
    // ページング
    itemsPerPageList: [10, 50, 100],
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    itemsPerPageBtn: "10",
    // 責任
    isRes: "01",
    isResBadValue: "01",
    // 有償/無償
    isPaid: "01",
    // 総件数
    totalCount: 0,
    // 数量件数
    sumCount: "",
    // 品質区分
    arrivalStatusSelected: "01",
    isDisabledFreeReason: true,
    isDisabledBlame: true,
    // 在庫差異確認ボタン活性、非活性
    compareBtnActive: true,
    // CSV出力ボタン活性、非活性
    outputBtnActive: true,

    // エラー確認画面に渡す用
    suppliersSelectedSearch: "",
    arrivalStatusSelectedSearch: "",
    isResSearch: "",
    isPaidSearch: "",
    processDivNameSearch: "",
    importfileSearch: "",
    arrivalScheduleDateSearch: "",
    freeReasonSelectedSearch: "",

    // 取引先CD
    clientCd: "",
    // 品質区分
    qualityDiv: "",
    // 責任区分
    blameDiv: "",
    // 変更理由区分
    changeReasonDiv: "",
    // 有償無償区分
    freeCostDiv: "",
    // 有償無償理由区分
    freecostReasonDiv: "",
    // 納品日
    inScheduleDate: "",
    // 運送会社区分
    carrierCompKbn: "",
    // 運送会社SID
    carrierCompSid: "",
    // 処理区分
    processDiv: "",

    // 無償理由
    freeReasonSelected: "",
    // 処理区分
    processDivName: "01",
    // 入荷予定配列
    inListCsvData: [],
    // 取込確定フラグ
    confirmFlg: true,
    // メニュー
    openMenu: null,
    csvData: [],
    // 表示の切り替え
    activate: false,

    // エラー画面遷移用取引先cd,name
    supplierCdName: "",

    // 状態区分
    status: "",
    // 作成日
    createDate: "",
    // 作成時間
    createTime: "",
    // 会社CD
    compCd: "",
    // 拠点CD
    baseCd: "",
    // 出荷予定日
    outScheduleDateYmd: "",
    // 納入予定日
    deliveryScheduleDateYmd: "",
    // 便No
    binNo: "",
    // 伝票No
    slipNo: "",
    // 伝票明細No
    slipDtlNo: "",
    // 出荷No
    outListNo: "",
    // 出荷先CD
    toCd: "",
    // 郵便番号
    postCd: "",
    // 都道府県
    prefectures: "",
    // 市区町村
    municipalities: "",
    // 町域名
    characterName: "",
    // 住所詳細IF
    addressDetailIf: "",
    // 電話番号
    telephoneNumber: "",
    // 担当者
    repName: "",
    // 備考
    remarks: "",
    // 品番
    itemCd: "",
    // 品名
    itemName: "",
    // 出荷予定数
    shipScheduleQuantity: "",
    // ロット
    lotNo: "",
    // 実重量
    actualWeight: "",
    // 請求重量
    pieceCapacityWeight: "",
    // 実立米
    actualQuantity: "",
    // 請求立米
    pieceCapacityQuantity: "",
    // 輸送会社CD
    carrierCompCd7: "",
    // JANCD
    janCd: "",
    // ITFCD
    itfCd: "",
    // 品番代替CD
    itemSurrogateCd: "",
    // 取引先データ作成日
    clientCreateDate: "",
    // 取引先データ作成時間
    clientCreateTime: "",
    // 無償理由エラーメッセージ
    freeReasonAlertMessage: "",
    arrivalStatusList: [],
    processDivList: [],
    alertMessage: "",
    alertSupplierMessage: "",
    alertDateMessage: "",
    fileCheckDialogMessage: false,
    // 戻るボタンダイアログメッセージ
    backMessage: "",
    // 詳細ポップアップ
    dialogDetails: false,
    errorDetailDialogData: [],
    // 詳細ERROR画面へ渡す
    detailDialogData: {
      suppliersSelectedName: "",
      list: [],
    },
    // ヘッダ
    headerItems: [
      // 品番/品名
      {
        text: i18n.tc("label.lbl_productCnCd"),
        value: "product",
        width: "18%",
        align: "left",
        sortable: false,
      },
      // 付帯品番
      {
        text: i18n.tc("label.lbl_accessoryProduct"),
        value: "isInListNo",
        width: "8%",
        align: "left",
        sortable: false,
      },
      // ロット
      {
        text: i18n.tc("label.lbl_lot"),
        value: "inListNo",
        width: "13%",
        align: "center",
        sortable: false,
      },
      // 在庫数
      {
        text: i18n.tc("label.lbl_stockQty"),
        value: "stock",
        width: "10%",
        align: "right",
        sortable: false,
      },
      // 荷主在庫数
      {
        text: i18n.tc("label.lbl_receivingStockQty"),
        value: "consinorStock",
        width: "11%",
        align: "right",
        sortable: false,
      },
      // 在庫差異
      {
        text: i18n.tc("label.lbl_stockTransitionQty"),
        value: "diffStock",
        width: "10%",
        align: "right",
        sortable: false,
      },
      // 単価Gr
      {
        text: i18n.tc("label.lbl_unitPriceGr"),
        value: "unitGr",
        width: "10%",
        align: "left",
        sortable: false,
      },
    ],
    // ダミーデータ
    item: [],
    inputList: [],
    rules: {
      yyyymmdd: function (value) {
        if (!value) {
          return true;
        }
        if (
          !(
            dateTimeHelper.validDate(commonUtil.zen2han(value), "/") && dateTimeHelper.isDate(value)
          )
        ) {
          return i18n.tc("check.chk_inputDate");
        }
        return true;
      },
      inputRequired: (value) => !!value || i18n.tc("check.chk_input"),
      fileRequired: (value) => (!!value && !!value.name) || i18n.tc("check.chk_select"),
      freeReasonInputRequired: (value) =>
        (!!value && this.isDisabledFreeReason == false) || i18n.tc("check.chk_select"),
    },
  }),
  methods: {
    // ダイアログクローズ
    closePopup() {
      this.dialogDetails = false;
    },
    // ダイアログを閉じる
    close() {
      this.fileCheckDialogMessage = false;
    },
    append() {
      this.truckKbn = "";
    },
    u_10_on() {
      this.u_10 = true;
      this.itemsPerPage = 10;
      this.page = 1;
      // 取り込み結果のAPIを設定するTODO
      this.getApiList(false);
    },
    u_10_clear(val) {
      this.u_10 = false;
      this.itemsPerPage = val;
      this.page = 1;
      // 取り込み結果のAPIを設定するTODO
      this.getApiList(false);
    },

    /**
     * 初期表示
     */
    init() {
      console.log("importfile", this.importfile);

      this.suppliersSelected = "";
      this.txt_arrivalScheduleNo = "";
      this.txt_slipNo = "";
      this.itemsPerPage = 10;
      this.itemsPerPageBtn = "10";
      this.inputList = [];
      this.freeReasonSelected = "";
      this.processDivName = "01";
      // this.compareScheduleDateCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
      this.compareScheduleDate = "";
      this.compareScheduleDateCal = "";

      // 取引先取得
      this.getCustomInfo();
      // マスタ取得
      this.getMstCode();

      for (let i = 0; i < this.item.length; i++) {
        this.inputList.push(this.item[i]);
      }
      this.totalCount = this.item.length;
    },
    open() {
      // this.ProdNameSelected = "";
      // this.warehouseSelected = "";
      // this.txt_location = "";
      // this.locationList = [];
      // this.pageCount = 1;
      // this.totalCount = 0;
      // this.$emit("close");
      this.newwin = open("http://localhost:8080/P-SRG-001");
    },

    // 日付入力
    changeDate(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.compareScheduleDateCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.compareScheduleDateCal = null;
      }
    },

    /**
     * 初期データ取得
     */
    getCustomInfo() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // 取引先
      const clientList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertUTC()
      );

      Promise.all([clientList])
        .then((result) => {
          const list = [];
          list.push({
            text: `${result[0].clientCd}` + `（${result[0].relatedPartyName}）`,
            value: result[0].relatedPartySid,
            name: result[0].relatedPartyName,
            clientSid: result[0].compSid, // 取引先Sid
            officeCd: result[0].clientCd, // 取引先Cd
          });
          // 画面の初期値を設定します。
          // this.supplierList = list;
          this.supplierList = result[0];
          console.log("this.supplierList : " + this.supplierList);
          this.supplierCdName = this.supplierList[0].text;
          console.log("this.supplierCdName : " + this.supplierCdName);
          this.clientCd = this.supplierCdName.substr(0, 7);
          console.log("this.clientCd : " + this.clientCd);
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    /**
     * 取込確定ボタン押下
     */
    confirm() {
      // ボタン識別フラグ（trueだったら、取込確定）
      this.confirmFlg = true;

      this.ConfirmDialog.isOpen = true;
      this.ConfirmDialog.screenFlag = true;
      this.ConfirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_importConfirm"),
      ]);
    },
    // 処理区分
    setStsArrival() {
      for (var i = 0; i < this.processDivList.length; i++) {
        if (this.processDivList[i].value == this.processDivName) {
          return this.processDivList[i].name;
        }
      }
    },
    /**
     * 画面クリア押下
     */
    clear() {
      // ボタン識別フラグ（falseだったら、画面クリア）
      this.confirmFlg = false;

      this.ConfirmDialog.isOpen = true;
      this.ConfirmDialog.screenFlag = true;
      this.ConfirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_clear"),
      ]);
    },
    /**
     * アップロード後画面クリア
     */
    afterUploadClear() {
      // 画面クリア処理
      this.inputList = [];
      this.importfile = null;
      this.totalCount = 0;
      this.sumCount = "";
      this.arrivalStatusSelected = "01";
      this.activate = false;
      this.freeReasonSelected = "";
      this.processDivName = "01";
      this.itemsPerPage = 10;
      this.itemsPerPageBtn = "10";
      this.isPaid = "01";
    },

    /**
     * 取込前のバリデーションチェック
     */
    search() {
      console.log(this.suppliersSelected);
      if (this.$refs.form.validate()) {
        // 取込確定確認POPUP処理
        this.csvImport();
        console.log("通っている？");
      }
    },
    readFile(reader) {
      console.log("reader.result:" + reader.result);
    },

    /**
     * 取込ボタン押下処理
     */
    csvImport() {
      // 取込確定処理
      // 選択したファイルを読み込む
      const csvFile = this.importfile;

      this.importCSV(csvFile)
        .then((result) => {
          this.inListCsvData = result;
        })
        .then(() => {
          this.csvConfirm();
        });
    },

    /**
     * マスタコード取得
     */
    getMstCode() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 処理区分
      const processList = getParameter.getCodeMst(appConfig.CODETYPE.RESULT_DISPLAY_DIV);

      Promise.all([processList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.processDivList = result[0];
          this.processDivName = this.processDivList[0].value;
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    /**
     * POSTAPI(本登録POST)
     */
    csvConfirm() {
      if (this.confirmFlg) {
        // 無償理由入力チェック
        // if (
        //   this.isPaid == "02" &&
        //   (this.freeReasonSelected == null || this.freeReasonSelected == "")
        // ) {
        //   this.freeReasonAlertMessage = i18n.tc("check.chk_input");
        //   return;
        // }

        if (this.inListCsvData.length < 2) {
          this.fileCheckDialogMessage = true;
          this.backMessage = messsageUtil.getMessage("P_SRG_005_001_E");
          return;
        }
        // var checkCnt = [];
        // for (var a = 0; a < this.inListCsvData.length; a++) {
        //   if (this.inListCsvData[a].check) {
        //     checkCnt.push(this.inListCsvData[a]);
        //   }
        // }
        // //1件もチェックがない場合
        // if (checkCnt.length < 1 || checkCnt == "") {
        //   this.checkDialogMessage = true;
        //   this.backMessage = messsageUtil.getMessage("P-EXT-003_001_E");
        //   return;
        // }

        // 在庫差異比較ボタン押下時明細クリア
        this.inputList = [];
        // ローディング画面表示ON
        this.loadingCounter = 1;
        const body = this.$httpClient.createRequestBodyConfig();

        //  画面ID TODO:修正必要
        body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_SRG_005;

        // 取引先SID
        body.reqIdv.clientSid = this.suppliersSelected;
        // ファイル名
        body.reqIdv.fileName = this.importfile.name;

        // let fileTypeKbn;
        // const fileType = this.importfile.name.substr(-3);
        // if (fileType.toUpperCase() == "CSV") {
        //   fileTypeKbn = "01";
        // }
        // if (fileType.toUpperCase() == "TXT") {
        //   fileTypeKbn = "02";
        // }
        // 取込ファイル区分
        // body.reqIdv.captureKbn = fileTypeKbn;
        // 結果表示区分
        body.reqIdv.resultDisplayFormat = this.processDivName;
        // 比較配列
        body.reqIdv.listData = this.inListCsvData;
        // タイムゾーン
        body.reqIdv.timeZone = dateTimeHelper.getTimeZone();
        // 荷主在庫比較フラグ(0005)
        body.reqIdv.inOutDiv = "0005";

        // 取引先CD
        body.reqIdv.clientCd = this.clientCd;

        // 比較予定日yyyymmddHhmmss
        if (this.compareScheduleDate == "" || this.compareScheduleDate == null) {
          // 何もしない
        } else {
          body.reqIdv.compareScheduleDateYmd = dateTimeHelper.convertUTC(this.compareScheduleDate);
        }

        console.log("body", body);
        console.log("postCsv() Body", body);

        return new Promise((resolve, reject) => {
          this.$httpClient
            // 接続先のAPI_URLを入力
            .securePost(appConfig.API_URL.STOCK_CONSIGNOR_COMPARE, body, appConfig.APP_CONFIG)
            .then((response) => {
              console.log("データ取込本登録POST", response);
              const jsonData = JSON.parse(JSON.stringify(response.data));
              console.log(jsonData);

              // 正常時
              if (jsonData.resCom.resComCode == "000") {
                console.log("success");
                if (jsonData.resIdv.inventoryComparison.csvErrorCheckResultList.length != 0) {
                  const errorList = [];
                  this.errorDetailDialogData = [];
                  jsonData.resIdv.inventoryComparison.csvErrorCheckResultList.forEach((row) => {
                    errorList.push({
                      listNo: row.listNo,
                      row: row.row,
                      column: row.column,
                      message: row.message,
                      value: row.value,
                    });
                    this.errorDetailDialogData = errorList;
                  });
                  // エラー一覧タブが開く
                  // 詳細画面へ渡す値を設定します。
                  this.detailDialogData.list = this.errorDetailDialogData;
                  // 選択された列名
                  this.detailDialogData.suppliersSelectedName = this.setSrhSupplier();
                  this.detailDialogData.supplierCdName = this.supplierCdName;
                  // if (jsonData.resIdv.inventoryComparison.csvErrorCheckResultList.length > 0) {
                  this.dialogDetails = true;
                  // }
                } else {
                  // this.pageCount = jsonData.resCom.resComPagination.totalPage;
                  const list = [];
                  // ファイル名
                  this.fileName = jsonData.resIdv.inventoryComparison.fileName;
                  // S3登録ファイル名
                  this.s3FileName = jsonData.resIdv.inventoryComparison.s3FileName;
                  // 画面表示項目
                  jsonData.resIdv.inventoryComparison.csvResults.forEach((row) => {
                    let isErrFlg = false;
                    let errMsg = "";
                    let rcvDate = "";
                    if (row.errMsg) {
                      isErrFlg = true;
                      errMsg = row.errMsg;
                      errMsg = errMsg.replace("\r\n", "<br />");
                      this.activate = false;
                    }
                    list.push({
                      isInListNoFlg: isErrFlg,
                      isShipInsDateFlg: isErrFlg,
                      isItemCdFlg: isErrFlg,
                      isItemNameFlg: isErrFlg,
                      isInQtyeFlg: isErrFlg,
                      isFromNameFlg: isErrFlg,
                      isAddInfoFlg: isErrFlg,
                      // 行数
                      no: row.No,
                      // 品番
                      itemCd: row.itemCd,
                      // 品名
                      itemName: row.itemName,
                      // 付帯品番
                      isInListNo: row.ancillaryItemName,
                      // ロット
                      inListNo: row.lotNo,
                      // 在庫数
                      stock: row.stock,
                      // 荷主在庫数
                      consinorStock: row.consignorStock,
                      // 在庫差異
                      diffStock: row.diffStock,
                      // 単価Gr
                      unitGr: row.unitGr,
                      accInfos: errMsg,
                      // 比較予定日
                      compareScheduleDate: rcvDate,
                    });
                  });
                  // 画面表示用リストに格納
                  this.inputList = list;
                  this.outputBtnActive = false;
                  this.isErr();
                  resolve(response);
                }
              } else {
                this.infoDialog.message = jsonData.resCom.resComMessage;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = true;
                // エラー時
                // const errorList = [];
                // エラー情報
                // jsonData.resIdv.errors.forEach((row) => {
                //   errorList.push({
                //     // 行数
                //     row: row.row,
                //     // 項目数
                //     columnName: row.columnName,
                //   });
                // });
                reject();
              }
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;

              reject();
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        });
      } else {
        console.log("importfile", this.importfile);
        // 画面クリア処理
        this.inputList = [];
        this.importfile = null;
        this.totalCount = 0;
        this.sumCount = "";
        this.arrivalStatusSelected = "01";
        this.activate = false;
        this.freeReasonSelected = "";
        this.processDivName = "01";
        this.itemsPerPage = 10;
        this.itemsPerPageBtn = "10";
        this.isPaid = "01";
        this.suppliersSelected = "";

        // バリデーションチェックをリセット
        this.$refs.form.resetValidation();
        // this.$refs.vFileInput.resetValidation();
      }
    },
    isErr() {
      let countErr = 0;
      for (var i = 0; i < this.inputList.length; i++) {
        if (this.inputList[i].isInListNoFlg) {
          countErr += 1;
        }
      }
      if (countErr == 0) {
        this.activate = true;
      }
    },

    /**
     * 比較予定登録画面：比較予定日付を－１日
     */
    prevDate() {
      let date = new Date(this.compareScheduleDateCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.compareScheduleDateCal == null || this.compareScheduleDateCal == "") {
        return;
      }
      if (this.compareScheduleDateCal != toDate) {
        this.compareScheduleDateCal = toDate;
      } else {
        this.compareScheduleDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
      }
    },

    /**
     * 比較予定登録画面：比較予定日付を＋１日
     */
    nextDate() {
      let date = new Date(this.compareScheduleDateCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.compareScheduleDateCal == null || this.compareScheduleDateCal == "") {
        return;
      }
      if (this.compareScheduleDateCal != toDate) {
        this.compareScheduleDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 2));
      } else {
        this.compareScheduleDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
      }
    },

    //データ出力ボタン押下時
    getOutPut() {
      var now = new Date();
      this.today = dateTimeHelper.toStringDate("YYYY/MM/DD", now);
      this.nowTime = dateTimeHelper.toStringDate("hh:mm", now);
      // 現在時刻を表示

      if (this.$refs.form.validate()) {
        this.dataOutPutDialog = true;
      }
    },
    /**
     * CSV出力前のバリデーションチェック
     */
    beforeCsv() {
      if (this.$refs.form.validate()) {
        // CSV出力処理
        this.outPutClick();
        console.log("CSV通っている？");
      }
    },

    // 荷主在庫比較出力：データ出力ボタン押下時
    outPutClick() {
      // 出力警告ダイアログ表示
      // if (this.inputList.length == 0 || this.inputList.length == null) {
      //   this.updateDialogMessage = true;
      //   this.backMessage = messsageUtil.getMessage("P_SRG_004_001_E");
      // }
      // if (!this.importfile) {
      //   return;
      // }
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const config = this.$httpClient.createRequestBodyConfig();

      config.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_SRG_005;

      // 取引先SID
      config.reqIdv.clientSid = this.suppliersSelected;

      // 比較予定日
      if (this.compareScheduleDate == "" || this.compareScheduleDate == null) {
        // 何もしない
      } else {
        config.reqIdv.compareScheduleDateYmd = dateTimeHelper.convertUTC(this.compareScheduleDate);
      }
      // 入出区分
      config.reqIdv.inOutDiv = "0005";
      // 結果表示形式
      config.reqIdv.resultDisplayFormat = this.processDivName;
      // ファイル名
      config.reqIdv.fileName = this.fileName;
      // CSV行リスト
      config.reqIdv.listData = this.inListCsvData;

      // // 商品標準SID
      // if (this.productCodeSelected) {
      //   config.reqIdv.itemStandardSid = this.productCodeSelected;
      // }

      // //表示期間
      // if (this.displayPeriodSelected) {
      //   config.reqIdv.displayPeriod = this.displayPeriodSelected;
      // }

      // // 品質区分
      // if (this.qualitySelected) {
      //   config.reqIdv.qualityDiv = this.qualitySelected;
      // }

      // // 出力ファイル形式区分
      // config.reqIdv.outputFileDiv = this.dataFormatSelected;

      // // 出力パターン区分
      // config.reqIdv.outputPatternDiv = this.outPutPatternSelected;

      // // 基準日
      // if (this.referenceDateCal) {
      //   let dateRange = this.referenceDateCal + " 00:00:00.000";
      //   config.reqIdv.referenceDate = dateTimeHelper.convertUTC(dateRange);
      // }

      // // 推移区分
      // if (this.transactionTypeSelected) {
      //   config.reqIdv.transitionDiv = this.transactionTypeSelected;
      // }

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(
            appConfig.API_URL.STOCK_CONSIGNOR_COMPARE_DOWNLOAD,
            config,
            appConfig.APP_CONFIG
          )
          .then((response) => {
            // // console.debug("btnSearch() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              // 返却されたurlを共通関数へ呼び出し
              commonFunction.getLink(jsonData.resIdv.filePath);
              resolve(response);
            } else {
              // エラー時(件数0件またはapiエラー時)
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    /**
     * 取引先コードリストボックス取得処理
     */
    setSrhSupplier() {
      for (var i = 0; i < this.supplierList.length; i++) {
        if (this.supplierList[i].value == this.suppliersSelected) {
          return this.supplierList[i].name;
        }
      }
    },

    /**
     * 取引先コー取得
     */
    getSupplierCd() {
      for (var i = 0; i < this.supplierList.length; i++) {
        if (this.supplierList[i].value == this.suppliersSelected) {
          return this.supplierList[i].officeCd;
        }
      }
    },

    /**
     * メニューダイアログ
     */
    showMenu() {
      console.log("List1 showMenu");
      this.openMenu = !this.openMenu;
    },

    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },
    /**
     * アイコンを押下しても、エクスプローラーが開く処理
     */
    handleClickAppendOuter() {
      this.$refs.vFileInput.$el.querySelector("input").click();
    },
  },
  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    compareScheduleDateCal(val) {
      this.compareScheduleDate = this.formatDate(val);
      this.alertDateMessage = "";
    },
    page: function (newValue, oldValue) {
      console.log("pageCount", newValue, oldValue);
      this.page = newValue;
      this.getApiList(false);
    },
    isPaid: function (newVal, oldVal) {
      console.log("isPaid", newVal, oldVal);
      if (newVal == "02") {
        this.isDisabledFreeReason = false;
      } else {
        this.isDisabledFreeReason = true;
        this.freeReasonSelected = "";
        this.processDivName = "01";
      }
    },
    freeReasonSelected() {
      this.freeReasonAlertMessage = "";
    },
    arrivalStatusSelected: function (newVal, oldVal) {
      console.log("arrivalStatusSelected", newVal, oldVal);
      if (newVal == "02") {
        this.isDisabledBlame = false;
        this.isRes = this.isResBadValue;
      } else {
        this.isDisabledBlame = true;
        this.isResBadValue = this.isRes;
        this.isRes = "01";
      }
    },
    // ファイル拡張子チェック
    importfile() {
      const fileType = this.importfile.name.substr(-3);
      // ファイル拡張子チェック
      if (fileType.toUpperCase() == "CSV") {
        console.log("fileType : " + fileType);
        this.alertMessage = "";
        if (this.suppliersSelected == "" || this.suppliersSelected == null) {
          this.alertSupplierMessage = i18n.tc("check.chk_input");
        } else {
          this.compareBtnActive = false;
        }
      } else {
        this.alertMessage = i18n.tc("check.chk_limitFileType");
      }
    },
    suppliersSelected() {
      // メッセージクリア
      this.alertSupplierMessage = "";
      if (this.suppliersSelected != "") {
        if (this.alertMessage == "") {
          if (this.importfile.name != null) {
            this.compareBtnActive = false;
          }
          return;
        } else {
          return;
        }
      }
    },
    sortOptions: {
      handler(items) {
        if (items.sortBy.length < 1) {
          this.sortItem = "";
        } else {
          var sortBy = items.sortBy[0];
          var ascdesc = items.sortDesc[0] ? "desc" : "asc";
          this.sortItem = sortBy + " " + ascdesc;
          if (sortBy == "no") {
            this.inputList.sort((a, b) => {
              if (ascdesc == "asc") {
                return a.no - b.no;
              } else {
                return b.no - a.no;
              }
            });
          } else if (sortBy == "inListNo") {
            this.inputList.sort((a, b) => {
              if (ascdesc == "asc") {
                if (a.inListNo < b.inListNo) {
                  return -1;
                } else if (a.inListNo > b.inListNo) {
                  return 1;
                } else {
                  return 0;
                }
              } else {
                if (a.inListNo > b.inListNo) {
                  return -1;
                } else if (a.inListNo < b.inListNo) {
                  return 1;
                } else {
                  return 0;
                }
              }
            });
          } else if (sortBy == "shipInsDate") {
            this.inputList.sort((a, b) => {
              if (ascdesc == "asc") {
                if (a.shipInsDate < b.shipInsDate) {
                  return -1;
                } else if (a.shipInsDate > b.shipInsDate) {
                  return 1;
                } else {
                  return 0;
                }
              } else {
                if (a.shipInsDate > b.shipInsDate) {
                  return -1;
                } else if (a.shipInsDate < b.shipInsDate) {
                  return 1;
                } else {
                  return 0;
                }
              }
            });
          } else if (sortBy == "inScheduleQuantity") {
            this.inputList.sort((a, b) => {
              if (ascdesc == "asc") {
                return a.inScheduleQuantity - b.inScheduleQuantity;
              } else {
                return b.inScheduleQuantity - a.inScheduleQuantity;
              }
            });
          } else if (sortBy == "toName") {
            this.inputList.sort((a, b) => {
              if (ascdesc == "asc") {
                if (a.toName < b.toName) {
                  return -1;
                } else if (a.toName > b.toName) {
                  return 1;
                } else {
                  return 0;
                }
              } else {
                if (a.toName > b.toName) {
                  return -1;
                } else if (a.toName < b.toName) {
                  return 1;
                } else {
                  return 0;
                }
              }
            });
          } else if (sortBy == "product") {
            this.inputList.sort((a, b) => {
              if (ascdesc == "asc") {
                if (a.itemCd < b.itemCd) {
                  return -1;
                } else if (a.itemCd > b.itemCd) {
                  return 1;
                } else {
                  return 0;
                }
              } else {
                if (a.itemCd > b.itemCd) {
                  return -1;
                } else if (a.itemCd < b.itemCd) {
                  return 1;
                } else {
                  return 0;
                }
              }
            });
          }
          return this.inputList;
        }
      },
      deep: true,
    },
  },

  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
//画面優先順位

.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}

//送り先登録時、色変更
.isD {
  // color: $color-error !important;
  color: black !important;
  float: right;
}

.isB {
  color: rgb(9, 9, 226) !important;
  float: right;
}
.isR {
  color: rgb(219, 5, 5) !important;
  float: right;
}

//備考欄、テキストボックス
.search-textbox-remark {
  width: 40rem;
}
</style>
